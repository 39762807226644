import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  Button,
  TextField,
  CircularProgress,
  Select,
  MenuItem,
  Alert,
  FormControl,
  InputLabel,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  Grid,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TableSortLabel,
  ToggleButtonGroup,
  ToggleButton,
  useMediaQuery,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import { Chess } from "chess.js";
//import ExpandableTypography from "../../../components/ExpandableTypograph";
import {
  eco_1,
  eco_2,
  eco_3,
  eco_4,
  eco_5,
  eco_6,
  eco_7,
  eco_8,
} from "../../../data/eco/eco";
import { ResponsiveLine } from "@nivo/line";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsiveCirclePacking } from "@nivo/circle-packing";
import { ResponsiveSunburst } from "@nivo/sunburst";
import { ResponsiveCalendar } from "@nivo/calendar";
import BulletIcon from "../../../icons/BulletIcon";
import BoltIcon from "../../../icons/BoltIcon";
import RapidIcon from "../../../icons/RapidIcon";
import DailyIcon from "../../../icons/DailyIcon";
import ClassicalIcon from "../../../icons/ClassicalIcon";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import PositionViewer from "../../../components/PositionViewer";
import { Tree } from "antd";
import { useShare } from "../../../context/ShareContext";

function UserScout() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(false);
  const [ratingHistory, setRatingHistory] = useState(null);
  const [publicData, setPublicData] = useState(null);
  const [selectedGameType, setSelectedGameType] = useState("All");
  const [timeAggregation, setTimeAggregation] = useState("Month");
  const [activeTab, setActiveTab] = useState(0);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("games");
  const [view, setView] = useState("results");
  const [activityView, setActivityView] = useState("calendar");
  const [tableData, setTableData] = useState([]);
  const [colorView, setColorView] = useState("white");
  const [totalGames, setTotalGames] = useState(0);
  const [numberOfGames, setNumberOfGames] = useState(1000);
  const [maxMoves, setMaxMoves] = useState(3);
  const [rawMoves, setRawMoves] = useState([]);
  const [performanceData, setPerformanceData] = useState([]);
  const [selectedRootNode, setSelectedRootNode] = useState("All");
  const [circlePackingData, setCirclePackingData] = useState({
    name: "root",
    children: [],
  });
  const { setShareData } = useShare();
  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/userscout",
      title: "Chessboard Magic - User Scout",
      description:
        "Discover and analyze player profiles on Lichess with User Scout! Track progress, review stats, and gain insights effortlessly.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const [treeData, setTreeData] = useState({
    title: "root",
    key: "root",
    children: [],
  });
  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("info");

  const showAlert = (message, severity = "info") => {
    console.log("Message: ", message);
    setAlertMessage(message);
    setAlertSeverity(severity);
  };

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const handleSelectChange = (event) => {
    handleTabChange(event, event.target.value);
  };

  const handleMaxMovesChange = (event) => {
    const newMaxMoves = event.target.value;
    setMaxMoves(newMaxMoves);
    setCirclePackingData(generateCirclePackingData(rawMoves, newMaxMoves));
    setTreeData(generateTreeData(rawMoves, newMaxMoves));
  };

  const [openingNarrative, setOpeningNarrative] = useState("");
  const [vizType, setVizType] = useState("Circles");

  const [calendarData, setCalendarData] = useState([]);

  const initializeGamesPerWeekDay = () => {
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    return weekdays.map((day) => ({
      day: day,
      ultrabullet: 0,
      bullet: 0,
      blitz: 0,
      rapid: 0,
      classical: 0,
      correspondence: 0,
    }));
  };

  const [gamesPerWeekDay, setGamesPerWeekDay] = useState(
    initializeGamesPerWeekDay()
  );

  const pgnToFen = (pgn) => {
    const newGame = new Chess();
    newGame.loadPgn(pgn);

    return newGame.fen();
  };

  // Handles the narration Generation
  // Define the game types and their corresponding labels
  const gameTypeLabels = {
    ultraBullet: "UltraBullet",
    bullet: "Bullet",
    blitz: "Blitz",
    rapid: "Rapid",
    classical: "Classical",
    correspondence: "Correspondence",
  };

  const getPerformanceStatistics = async () => {
    const baseUrl = `https://lichess.org/api/user/${username}/perf`;
    const gameTypes = Object.keys(gameTypeLabels);

    const promises = gameTypes.map(async (gameType) => {
      try {
        const response = await fetch(`${baseUrl}/${gameType}`);
        if (!response.ok) {
          throw new Error(`Error fetching data: ${response.statusText}`);
        }
        const data = await response.json();
        return {
          gameType: gameTypeLabels[gameType],
          data: data,
        };
      } catch (error) {
        console.error(
          `Error fetching data for ${gameTypeLabels[gameType]}:`,
          error
        );
        return {
          gameType: gameTypeLabels[gameType],
          data: null,
        };
      }
    });

    const performanceData = await Promise.all(promises);
    setPerformanceData(performanceData);
  };

  const getOpeningNameByPgn1 = (pgn) => {
    const opening = eco_1.find((opening) => opening.pgn === pgn);
    return opening ? opening.name : "Unknown Opening";
  };

  const generateOpeningNarrativeData = (games) => {
    const openingData = {
      white: {
        topMoves: [],
        other: [],
      },
      black: {
        topMoves: [],
        other: [],
      },
    };

    // Helper function to calculate win rate
    const calculateWinRate = (wins, games) => ((wins / games) * 100).toFixed(1);

    // Filter games where the player is white
    const whiteGames = games.filter((game) => game.whitePlayer === username);
    // Filter games where the player is black
    const blackGames = games.filter((game) => game.blackPlayer === username);

    // Group games by the first move for White
    const firstMoveGroupsWhite = whiteGames.reduce((acc, game) => {
      const firstMove = game.line.split(" ")[1];
      if (!acc[firstMove]) {
        acc[firstMove] = [];
      }
      acc[firstMove].push(game);
      return acc;
    }, {});

    // Group games by the first move for Black
    const firstMoveGroupsBlack = blackGames.reduce((acc, game) => {
      const firstMove = game.line.split(" ")[1];
      if (!acc[firstMove]) {
        acc[firstMove] = [];
      }
      acc[firstMove].push(game);
      return acc;
    }, {});

    // Process each first move group for White
    Object.keys(firstMoveGroupsWhite).forEach((firstMove) => {
      const gamesForMove = firstMoveGroupsWhite[firstMove];
      const totalGames = gamesForMove.length;
      const wins = gamesForMove.filter(
        (game) => game.winner === "white"
      ).length;

      // Group by line
      const lineGroups = gamesForMove.reduce((acc, game) => {
        const line = game.line;
        if (!acc[line]) {
          acc[line] = {
            name: game.opening,
            line: game.line,
            gamesPlayed: 0,
            wins: 0,
          };
        }
        acc[line].gamesPlayed += 1;
        if (game.winner === "white") {
          acc[line].wins += 1;
        }
        return acc;
      }, {});

      // Sort lines by games played and take the top 3
      const topLines = Object.values(lineGroups)
        .sort((a, b) => b.gamesPlayed - a.gamesPlayed)
        .slice(0, 3)
        .map((line) => ({
          name: line.name,
          line: line.line,
          gamesPlayed: line.gamesPlayed,
          winRate: calculateWinRate(line.wins, line.gamesPlayed),
        }));

      // Add to opening data
      openingData.white.topMoves.push({
        firstMove,
        gamesPlayed: totalGames,
        wins,
        topLines,
      });
    });

    // Sort top moves by games played and take the top 3 for White, store the rest in "other"
    const sortedWhiteMoves = openingData.white.topMoves.sort(
      (a, b) => b.gamesPlayed - a.gamesPlayed
    );

    openingData.white.topMoves = sortedWhiteMoves.slice(0, 3);
    openingData.white.other = sortedWhiteMoves.slice(3);

    // Process each first move group for Black
    Object.keys(firstMoveGroupsBlack).forEach((firstMove) => {
      const gamesForMove = firstMoveGroupsBlack[firstMove];
      const totalGames = gamesForMove.length;
      const wins = gamesForMove.filter(
        (game) => game.winner === "black"
      ).length;

      // Group by line
      const lineGroups = gamesForMove.reduce((acc, game) => {
        const lineParts = game.line.split(" ");
        const secondMove = lineParts.length > 2 ? lineParts[2] : "";
        const line = `${firstMove} ${secondMove}`;
        if (!acc[line]) {
          acc[line] = {
            name: game.opening,
            line: game.line,
            gamesPlayed: 0,
            wins: 0,
          };
        }
        acc[line].gamesPlayed += 1;
        if (game.winner === "black") {
          acc[line].wins += 1;
        }
        return acc;
      }, {});

      // Sort lines by games played and take the top 3
      const topLines = Object.values(lineGroups)
        .sort((a, b) => b.gamesPlayed - a.gamesPlayed)
        .slice(0, 3)
        .map((line) => ({
          name: line.name,
          line: line.line,
          gamesPlayed: line.gamesPlayed,
          winRate: calculateWinRate(line.wins, line.gamesPlayed),
        }));

      // Add to opening data
      openingData.black.topMoves.push({
        firstMove,
        gamesPlayed: totalGames,
        wins,
        topLines,
      });
    });

    // Sort top moves by games played and take the top 3 for Black, store the rest in "other"
    const sortedBlackMoves = openingData.black.topMoves.sort(
      (a, b) => b.gamesPlayed - a.gamesPlayed
    );

    openingData.black.topMoves = sortedBlackMoves.slice(0, 3);
    openingData.black.other = sortedBlackMoves.slice(3);

    return openingData;
  };

  const handleViewChange = (event, newView) => {
    if (newView !== null) {
      setView(newView);
    }
  };

  const handleActivityViewChange = (event, newView) => {
    if (newView !== null) {
      setActivityView(newView);
    }
  };

  const initializeGamesPerHour = () => {
    const hours = Array(24)
      .fill(0)
      .map((_, hour) => ({
        hour: `${hour}`,
        ultrabullet: 0,
        bullet: 0,
        blitz: 0,
        rapid: 0,
        classical: 0,
        correspondence: 0,
      }));
    return hours;
  };
  const [gamesPerHour, setGamesPerHour] = useState(initializeGamesPerHour());

  // Set default dates
  const today = new Date().toISOString().split("T")[0];
  const startingDate = new Date();
  startingDate.setFullYear(startingDate.getFullYear() - 3);
  const defaultStartDate = startingDate.toISOString().split("T")[0];

  const [startDate, setStartDate] = useState(defaultStartDate);
  const [endDate, setEndDate] = useState(today);

  const mergedEcos = [
    ...eco_1,
    ...eco_2,
    ...eco_3,
    ...eco_4,
    ...eco_5,
    ...eco_6,
    ...eco_7,
    ...eco_8,
  ];

  const handleUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const handleNumberOfGamesChange = (e) => {
    const value = e.target.value;
    const parsedValue = parseInt(value, 10);

    if (!isNaN(parsedValue) && parsedValue >= 0) {
      setNumberOfGames(parsedValue);
    }
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleFetchAll = async () => {
    setAlertMessage("");
    setLoading(true);
    try {
      await getProfile();
      await handleDownloadAndProcessGames();
    } catch (error) {
      console.error("Failed to fetch and process data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleDownloadAndProcessGames = async () => {
    setTotalGames(0);
    setRawMoves([]);
    setCalendarData([]); // Reset calendar data
    setGamesPerHour(initializeGamesPerHour()); // Reset games per hour
    setGamesPerWeekDay(initializeGamesPerWeekDay());

    if (!username) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `https://lichess.org/api/games/user/${username}?max=${numberOfGames}&format=ndjson&lastFen=true&division=true`,
        {
          headers: {
            Accept: "application/x-ndjson",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder("utf-8");
      let ndjsonData = "";

      const newGamesWithOpenings = [];
      const newCalendarData = {};
      const newGameTypeStats = {
        ultrabullet: { games: 0, wins: 0, draws: 0, losses: 0 },
        bullet: { games: 0, wins: 0, draws: 0, losses: 0 },
        blitz: { games: 0, wins: 0, draws: 0, losses: 0 },
        rapid: { games: 0, wins: 0, draws: 0, losses: 0 },
        classical: { games: 0, wins: 0, draws: 0, losses: 0 },
        correspondence: { games: 0, wins: 0, draws: 0, losses: 0 },
      };

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;
        ndjsonData += decoder.decode(value);
        const lines = ndjsonData.split("\n");
        ndjsonData = lines.pop(); // Keep the last incomplete line for the next read

        for (const l of lines) {
          let game;
          try {
            game = JSON.parse(l);
          } catch (e) {
            console.error("Failed to parse line as JSON:", l);
            continue;
          }

          if (!game.moves || typeof game.moves !== "string") {
            continue;
          }

          // Check if player names exist
          if (
            !game.players?.white?.user?.name ||
            !game.players?.black?.user?.name
          ) {
            console.warn("Game skipped due to missing player name:", game);
            continue;
          }

          const moves = game.moves.split(" ");
          const isWhite =
            game.players.white.user.name.toLowerCase() ===
            username.toLowerCase();
          const playerColor = isWhite ? "White" : "Black";
          const winner = game.winner;

          const gameDate = new Date(game.createdAt).toISOString().split("T")[0];
          const gameHour = new Date(game.createdAt).getHours();
          const gameDay = new Date(game.createdAt).getDay();
          const gameType = game.speed.toLowerCase(); // Get the game type (e.g., bullet, blitz)
          // Update game type stats
          newGameTypeStats[gameType].games += 1;
          if (winner === "white" && isWhite) {
            newGameTypeStats[gameType].wins += 1;
          } else if (winner === "black" && !isWhite) {
            newGameTypeStats[gameType].wins += 1;
          } else if (winner === "draw") {
            newGameTypeStats[gameType].draws += 1;
          } else {
            newGameTypeStats[gameType].losses += 1;
          }

          if (!newCalendarData[gameDate]) {
            newCalendarData[gameDate] = { day: gameDate, value: 0, wins: 0 };
          }

          newCalendarData[gameDate].value += 1;
          if (
            (isWhite && winner === "white") ||
            (!isWhite && winner === "black")
          ) {
            newCalendarData[gameDate].wins += 1;
          }

          setRawMoves((prevRawMoves) => {
            const newRawMoves = [
              ...prevRawMoves,
              { moves: game.moves, playerColor, winner },
            ];
            setCirclePackingData(
              generateCirclePackingData(newRawMoves, maxMoves)
            );
            setTreeData(generateTreeData(newRawMoves, maxMoves));
            return newRawMoves;
          });

          setGamesPerHour((prevGamesPerHour) => {
            const newGamesPerHour = [...prevGamesPerHour];
            newGamesPerHour[gameHour][gameType] += 1;
            return newGamesPerHour;
          });

          setGamesPerWeekDay((prevGamesPerWeekDay) => {
            const newGamesPerWeekDay = [...prevGamesPerWeekDay];
            newGamesPerWeekDay[gameDay][gameType] += 1;
            return newGamesPerWeekDay;
          });

          let openingName = "Unknown Opening";
          let ecoCode = "";
          let line = "";
          for (let i = 1; i <= 4; i++) {
            const currentMoves = moves.slice(0, i).join(" ");
            const matchedOpening = mergedEcos.find((eco) => {
              const ecoMoves = eco.pgn.replace(/[0-9]+\.\s/g, "").trim();
              return ecoMoves.startsWith(currentMoves);
            });
            if (matchedOpening) {
              openingName = matchedOpening.name;
              ecoCode = matchedOpening.eco;
              line = matchedOpening.pgn;
            } else {
              break;
            }
          }
          newGamesWithOpenings.push({
            id: game.id,
            opening: openingName,
            eco: ecoCode,
            line: line,
            speed: game.speed,
            whitePlayer: game.players.white.user.name,
            whiteRating: game.players.white.rating,
            blackPlayer: game.players.black.user.name,
            blackRating: game.players.black.rating,
            winner: game.winner,
            rated: game.rated,
            moves: moves.length,
            middle: game.division.middle,
            end: game.division.end,
          });

          setTotalGames((prev) => prev + 1);
        }

        updateTableData(newGamesWithOpenings);
        setCalendarData(Object.values(newCalendarData));
        setOpeningNarrative(generateOpeningNarrativeData(newGamesWithOpenings));
      }
    } catch (error) {
      console.log(error);
      console.error("Failed to fetch and process games", error);
    } finally {
      setLoading(false);
    }
  };

  const updateTableData = (gamesWithOpenings) => {
    const upperUsername = username.toUpperCase();

    const groupedGames = gamesWithOpenings.reduce((acc, game) => {
      const whitePlayer = game.whitePlayer.toUpperCase();
      const blackPlayer = game.blackPlayer.toUpperCase();
      const color = whitePlayer === upperUsername ? "white" : "black";
      const userRating =
        color === "white" ? game.whiteRating : game.blackRating;
      const opponentRating =
        color === "white" ? game.blackRating : game.whiteRating;

      const result =
        game.winner === color
          ? "win"
          : game.winner === undefined || game.winner === "draw"
          ? "draw"
          : "loss";

      if (!acc[game.opening]) {
        acc[game.opening] = {
          games: 0,
          wins: 0,
          losses: 0,
          draws: 0,
          totalMoves: 0,
          eco: game.eco,
          line: game.line,
          color: color,
          totalMiddle: 0,
          totalEnd: 0,
          scoreVsStronger: 0,
          scoreVsEqual: 0,
          scoreVsWeaker: 0,
          WinsVsStronger: 0,
          DrawsVsStronger: 0,
          LossesVsStronger: 0,
          WinsVsEqual: 0,
          DrawsVsEqual: 0,
          LossesVsEqual: 0,
          WinsVsWeaker: 0,
          DrawsVsWeaker: 0,
          LossesVsWeaker: 0,
        };
      } else {
        acc[game.opening].color = color;
      }

      acc[game.opening].games += 1;
      acc[game.opening].totalMoves += game.moves ?? 0;
      acc[game.opening].totalMiddle += game.middle ?? 0;
      acc[game.opening].totalEnd += game.end ?? 0;

      if (game.winner === "white" && whitePlayer === upperUsername) {
        acc[game.opening].wins += 1;
      } else if (game.winner === "black" && blackPlayer === upperUsername) {
        acc[game.opening].wins += 1;
      } else if (!game.winner) {
        acc[game.opening].draws += 1;
      } else {
        acc[game.opening].losses += 1;
      }

      if (opponentRating >= userRating + 50) {
        // Opponent is stronger
        acc[game.opening].scoreVsStronger +=
          result === "win" ? 1 : result === "loss" ? -1 : 0.5;
        if (result === "win") {
          acc[game.opening].WinsVsStronger += 1;
        } else if (result === "loss") {
          acc[game.opening].LossesVsStronger += 1;
        } else {
          acc[game.opening].DrawsVsStronger += 1;
        }
      } else if (opponentRating <= userRating - 50) {
        // Opponent is weaker
        acc[game.opening].scoreVsWeaker +=
          result === "win" ? 1 : result === "loss" ? -1 : 0.5;
        if (result === "win") {
          acc[game.opening].WinsVsWeaker += 1;
        } else if (result === "loss") {
          acc[game.opening].LossesVsWeaker += 1;
        } else {
          acc[game.opening].DrawsVsWeaker += 1;
        }
      } else {
        // Opponent is equal
        acc[game.opening].scoreVsEqual +=
          result === "win" ? 1 : result === "loss" ? -1 : 0.5;
        if (result === "win") {
          acc[game.opening].WinsVsEqual += 1;
        } else if (result === "loss") {
          acc[game.opening].LossesVsEqual += 1;
        } else {
          acc[game.opening].DrawsVsEqual += 1;
        }
      }

      return acc;
    }, {});

    const tableData = Object.keys(groupedGames).map((opening) => ({
      opening,
      eco: groupedGames[opening].eco,
      line: groupedGames[opening].line,
      color: groupedGames[opening].color,
      ...groupedGames[opening],
      averageMoves: (
        groupedGames[opening].totalMoves / groupedGames[opening].games
      ).toFixed(1),
      averageMiddle: isNaN(
        groupedGames[opening].totalMiddle / groupedGames[opening].games
      )
        ? "N/A"
        : (
            groupedGames[opening].totalMiddle / groupedGames[opening].games
          ).toFixed(1),
      averageEnd: isNaN(
        groupedGames[opening].totalEnd / groupedGames[opening].games
      )
        ? "N/A"
        : (
            groupedGames[opening].totalEnd / groupedGames[opening].games
          ).toFixed(1),
    }));

    setTableData(tableData);
  };

  const getMinMaxDates = (data) => {
    if (data.length === 0) return { minDate: today, maxDate: today };

    const dates = data.map((d) => new Date(d.day));
    const minDate = new Date(Math.min(...dates)).toISOString().split("T")[0];
    const maxDate = new Date(Math.max(...dates)).toISOString().split("T")[0];

    return { minDate, maxDate };
  };

  const getUniqueYears = (data) => {
    const years = new Set(data.map((d) => new Date(d.day).getFullYear()));
    return years.size;
  };

  const getProfile = async () => {
    console.log("Username: ", username);
    if (!username) {
      showAlert("Please enter a username", "error");
      return;
    }

    setLoading(true);

    try {
      await handleFetchPublicData();
      await handleFetchRatingHistory();
      await getPerformanceStatistics();
    } catch (error) {
      console.error("Failed to fetch profile data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchRatingHistory = async () => {
    if (!username) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `https://lichess.org/api/user/${username}/rating-history`
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const ratingHistoryData = await response.json();
      setRatingHistory(ratingHistoryData);
    } catch (error) {
      console.error("Failed to fetch rating history", error);
    } finally {
      setLoading(false);
    }
  };

  const handleFetchPublicData = async () => {
    if (!username) {
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(`https://lichess.org/api/user/${username}`);

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const userData = await response.json();
      setPublicData(userData);
    } catch (error) {
      console.error("Failed to fetch public data", error);
    } finally {
      setLoading(false);
    }
  };

  const handleGameTypeChange = (event) => {
    setSelectedGameType(event.target.value);
  };

  const handleTimeAggregationChange = (event) => {
    setTimeAggregation(event.target.value);
  };

  const handleStartDateChange = (event) => {
    setStartDate(event.target.value);
  };

  const handleEndDateChange = (event) => {
    setEndDate(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleColorViewChange = (event, newView) => {
    if (newView !== null) {
      setColorView(newView);
    }
  };

  const last60Days = new Date();
  last60Days.setDate(last60Days.getDate() - 2000);

  const fillGaps = (data) => {
    const filledData = [];
    let lastRating = null;

    const dataMap = new Map(data.map((d) => [d.x, d.y]));

    const startDate = new Date(Math.min(...data.map((d) => new Date(d.x))));
    const endDate = new Date();

    for (
      let d = new Date(startDate);
      d <= endDate;
      d.setDate(d.getDate() + 1)
    ) {
      const dateString = d.toISOString().split("T")[0];

      if (dataMap.has(dateString)) {
        lastRating = dataMap.get(dateString);
      }

      if (lastRating !== null) {
        filledData.push({ x: dateString, y: lastRating });
      }
    }

    return filledData;
  };

  const filterDataByAggregation = (data, aggregation) => {
    switch (aggregation) {
      case "Week":
        return data.filter((d) => new Date(d.x).getDay() === 1);
      case "Month":
        return data.filter((d) => new Date(d.x).getDate() === 1);
      default:
        return data;
    }
  };

  const filterDataByDateRange = (data) => {
    if (!startDate && !endDate) {
      return data;
    }

    return data.filter((d) => {
      const date = new Date(d.x);
      return (
        (!startDate || date >= new Date(startDate)) &&
        (!endDate || date <= new Date(endDate))
      );
    });
  };

  const filteredRatingHistory = ratingHistory
    ? ratingHistory
        .filter((history) =>
          selectedGameType === "All"
            ? true
            : history.name.toLowerCase() === selectedGameType.toLowerCase()
        )
        .map((history) => ({
          id: history.name,
          data: filterDataByDateRange(
            filterDataByAggregation(
              fillGaps(
                history.points
                  .map((entry) => ({
                    x: new Date(entry[0], entry[1] - 1, entry[2])
                      .toISOString()
                      .split("T")[0],
                    y: entry[3],
                  }))
                  .filter((dataPoint) => new Date(dataPoint.x) >= last60Days)
              ),
              timeAggregation
            )
          ),
        }))
        .filter((history) => history.data.length > 0)
    : [];

  const gameTypes = ratingHistory
    ? [
        "All",
        ...new Set(
          ratingHistory
            .filter((history) => history.points.length > 0)
            .map((history) => history.name)
        ),
      ]
    : ["All"];

  const sortedTableData = [...tableData].sort((a, b) => {
    if (orderBy === "opening" || orderBy === "eco" || orderBy === "line") {
      return order === "asc"
        ? a[orderBy].localeCompare(b[orderBy])
        : b[orderBy].localeCompare(a[orderBy]);
    }
    return order === "asc" ? a[orderBy] - b[orderBy] : b[orderBy] - a[orderBy];
  });

  const filteredTableData = sortedTableData.filter(
    (row) => row.color === colorView
  );

  const tabStyles = (isSelected) => ({
    border: "0.5px solid #DDDDDD",
    color: isSelected ? colors.black[900] : colors.black[500],
    "&.Mui-selected": {
      color: colors.black[900],
    },
    "&:hover": {
      color: colors.black[900],
    },
  });

  const formatScore = (score) => (score > 0 ? `+${score}` : score);

  const getFilteredCirclePackingData = (data, rootNode) => {
    if (rootNode === "All") {
      return data;
    }
    const rootChild = data.children.find((child) => child.name === rootNode);
    return rootChild
      ? { ...rootChild, name: rootNode }
      : { name: rootNode, children: [] };
  };

  const getFilteredTreeData = (data, rootNode) => {
    if (rootNode === "All") {
      return data;
    }

    const rootChild = data.children.find((child) =>
      child.title.startsWith(rootNode)
    );
    if (rootChild) {
      return {
        ...rootChild,
        title: rootNode,
      };
    }

    return { title: rootNode, children: [] };
  };

  const customTitleRender = (nodeData) => (
    <span>
      <span style={{ color: "black", fontWeight: "bold" }}>
        {nodeData.title.split(" ")[0]}
      </span>{" "}
      <span style={{ color: "#777777" }}>
        ({nodeData.value} Games, {nodeData.winRate} Win Rate)
      </span>
    </span>
  );

  // Function to generate tree data
  const generateTreeData = (games, maxMoves) => {
    const root = {
      title: "All",
      key: "All",
      children: [],
      wins: 0,
      losses: 0,
      value: 0, // Total games
    };

    const assignValue = (parentNode, moveSequence, move, isWin) => {
      const nodeKey = moveSequence.join(" ");
      let currentNode = parentNode.children.find(
        (child) => child.key === nodeKey
      );

      if (!currentNode) {
        currentNode = {
          title: move,
          key: nodeKey,
          children: [],
          wins: 0,
          losses: 0,
          value: 0, // Total games
        };
        parentNode.children.push(currentNode);
      }

      // Only assign value at the leaf node
      if (moveSequence.length === maxMoves + 1) {
        currentNode.value += 1;
        if (isWin) {
          currentNode.wins += 1;
        } else {
          currentNode.losses += 1;
        }
      }

      return currentNode;
    };

    games.forEach((game) => {
      if (!game || typeof game.moves !== "string") {
        console.error("Unexpected game object structure:", game);
        return;
      }

      const moves = game.moves
        .replace(/\d+\.\s?/g, "")
        .split(" ")
        .slice(0, maxMoves);

      const moveSequence = [game.playerColor, ...moves];
      let currentNode = root;

      moveSequence.forEach((move, index) => {
        const sequenceUpToCurrentMove = moveSequence.slice(0, index + 1);
        const isWin =
          game.winner === (game.playerColor === "White" ? "white" : "black");
        currentNode = assignValue(
          currentNode,
          sequenceUpToCurrentMove,
          move,
          isWin
        );
      });
    });

    const aggregateWinsAndValues = (node) => {
      if (!node.children || node.children.length === 0) {
        return { value: node.value || 0, wins: node.wins, losses: node.losses };
      }

      let totalValue = 0;
      let totalWins = 0;
      let totalLosses = 0;

      node.children.forEach((child) => {
        const { value, wins, losses } = aggregateWinsAndValues(child);
        totalValue += value;
        totalWins += wins;
        totalLosses += losses;
      });

      node.value = totalValue;
      node.wins = totalWins;
      node.losses = totalLosses;

      return { value: totalValue, wins: totalWins, losses: totalLosses };
    };

    aggregateWinsAndValues(root);

    // Calculate win and loss rates and update titles
    const calculateRates = (node) => {
      if (node.value > 0) {
        node.winRate = ((node.wins / node.value) * 100).toFixed(2) + "%";
        node.lossRate = ((node.losses / node.value) * 100).toFixed(2) + "%";
      } else {
        node.winRate = "0%";
        node.lossRate = "0%";
      }
      node.title = `${node.title} (Games: ${node.value}, Win Rate: ${node.winRate})`;
      node.children.forEach(calculateRates);
    };

    calculateRates(root);

    return root;
  };

  const generateCirclePackingData = (games, maxMoves) => {
    const root = { name: "All", children: [], wins: 0 };

    const assignValue = (parentNode, moveSequence, isWin) => {
      const nodeKey = moveSequence.join(" ");
      let currentNode = parentNode.children.find(
        (child) => child.name === nodeKey
      );

      if (!currentNode) {
        currentNode = { name: nodeKey, children: [], wins: 0 };
        parentNode.children.push(currentNode);
      }

      // Only assign value at the leaf node
      if (moveSequence.length === maxMoves + 1) {
        currentNode.value = (currentNode.value || 0) + 1;
        if (isWin) {
          currentNode.wins += 1;
        }
      }

      return currentNode;
    };

    games.forEach((game) => {
      if (!game || typeof game.moves !== "string") {
        console.error("Unexpected game object structure:", game);
        return;
      }

      const moves = game.moves
        .replace(/\d+\.\s?/g, "")
        .split(" ")
        .slice(0, maxMoves);

      const moveSequence = [game.playerColor, ...moves];
      let currentNode = root;

      moveSequence.forEach((move, index) => {
        const sequenceUpToCurrentMove = moveSequence.slice(0, index + 1);
        const isWin =
          game.winner === (game.playerColor === "White" ? "white" : "black");
        currentNode = assignValue(currentNode, sequenceUpToCurrentMove, isWin);
      });
    });

    const aggregateWinsAndValues = (node) => {
      if (!node.children || node.children.length === 0) {
        return { value: node.value || 0, wins: node.wins };
      }

      let totalValue = 0;
      let totalWins = 0;

      node.children.forEach((child) => {
        const { value, wins } = aggregateWinsAndValues(child);
        totalValue += value;
        totalWins += wins;
      });

      node.wins = totalWins;
      return { value: totalValue, wins: totalWins };
    };

    aggregateWinsAndValues(root);

    return root;
  };

  return (
    <Box>
      <ContentHeader
        title="User Scout"
        subtitle="Discover and analyze player profiles on Lichess with User Scout! Track progress, review stats, and gain insights effortlessly."
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[4]}
      />
      <Helmet>
        <title>User Scout</title>
        <meta
          name="description"
          content="Discover and analyze player profiles on Lichess with User Scout! Track progress, review stats, and gain insights effortlessly."
        />
        <meta property="og:title" content="User Scout" />
        <meta property="og:description" content="" />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/analytics/userscout.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/userscout`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="User Scout" />
        <meta name="twitter:description" content="" />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/analytics/userscout.png`}
        />
      </Helmet>
      <Box>
        {isSmallScreen ? (
          <Box display="flex" flexDirection="column" alignItems="center">
            <TextField
              variant="filled"
              label="Lichess Username"
              value={username}
              InputLabelProps={{
                style: { color: colors.black[100] },
              }}
              sx={{ mb: 2, width: "100%", color: "#AA0000" }}
              onChange={handleUsernameChange}
            />
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              width="100%"
            >
              <TextField
                variant="filled"
                label="# Games"
                value={numberOfGames}
                type="number"
                InputLabelProps={{
                  style: { color: "white" },
                }}
                inputProps={{ autoComplete: "off" }}
                sx={{ width: "30%", pr: 1 }}
                onChange={handleNumberOfGamesChange}
              />
              <Button
                variant="contained"
                onClick={handleFetchAll}
                disabled={loading}
                sx={{ width: "30%" }}
              >
                {loading ? <CircularProgress size={24} /> : "Import"}
              </Button>
              {alertMessage && (
                <Alert severity={alertSeverity} sx={{ marginLeft: 2 }}>
                  {alertMessage}
                </Alert>
              )}
              <Typography sx={{ ml: 1, width: "30%", textAlign: "center" }}>
                Loaded: <b>{totalGames}</b>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box display="flex" alignItems="center">
            <TextField
              variant="filled"
              label="Lichess Username"
              value={username}
              InputLabelProps={{
                style: { color: "black" },
              }}
              sx={{ pr: 2 }}
              onChange={handleUsernameChange}
            />
            <TextField
              variant="filled"
              label="# Games"
              value={numberOfGames}
              type="number"
              InputLabelProps={{
                style: { color: "black" },
              }}
              inputProps={{ autoComplete: "off" }}
              sx={{ width: 100 }}
              onChange={handleNumberOfGamesChange}
            />
            <Button
              variant="contained"
              onClick={handleFetchAll}
              disabled={loading}
              sx={{ marginLeft: 2 }}
            >
              {loading ? <CircularProgress size={24} /> : "Import"}
            </Button>
            {alertMessage && (
              <Alert severity={alertSeverity} sx={{ marginLeft: 2 }}>
                {alertMessage}
              </Alert>
            )}
            <Typography sx={{ marginLeft: 2 }}>
              Loaded: <b>{totalGames}</b>
            </Typography>
          </Box>
        )}
      </Box>

      <Box sx={{ marginTop: 2, maxWidth: "1200px" }}>
        <div>
          {isSmallScreen ? (
            <Select
              value={activeTab}
              onChange={handleSelectChange}
              fullWidth
              sx={tabStyles}
            >
              <MenuItem value={0}>Overview</MenuItem>
              <MenuItem value={1}>Ratings Graph</MenuItem>
              <MenuItem value={2}>Opening Statistics</MenuItem>
              <MenuItem value={3}>Opening Explorer</MenuItem>
              <MenuItem value={4}>Player Activity</MenuItem>
            </Select>
          ) : (
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab label="Overview" sx={tabStyles(activeTab === 0)} />
              <Tab label="Ratings Graph" sx={tabStyles(activeTab === 1)} />
              <Tab label="Opening Statistics" sx={tabStyles(activeTab === 2)} />
              <Tab label="Opening Explorer" sx={tabStyles(activeTab === 3)} />
              <Tab label="Player Activity" sx={tabStyles(activeTab === 4)} />
            </Tabs>
          )}
        </div>
        {
          /* Overview */ activeTab === 0 && (
            <Box mt={2} mb={2}>
              <div>
                {performanceData.length > 0 && (
                  <TableContainer
                    component={Paper}
                    sx={{ width: "100%", marginTop: 2 }}
                  >
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Time Control</TableCell>
                          <TableCell>
                            Peak Rating
                            <br />
                            <small>Non-Provisional</small>
                          </TableCell>
                          <TableCell>Current Rating</TableCell>
                          <TableCell>Number of Games</TableCell>
                          <TableCell>Wins</TableCell>
                          <TableCell>Draws</TableCell>
                          <TableCell>Losses</TableCell>
                          <TableCell>W / D / L</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {performanceData.map((item, index) => {
                          if (!item.data) return null;

                          const rating = Math.floor(
                            item.data.perf.glicko.rating
                          );
                          const nb = item.data.perf.nb;
                          const wins = item.data.stat.count.win;
                          const draws = item.data.stat.count.draw;
                          const losses = item.data.stat.count.loss;
                          const highest = item.data.stat.highest
                            ? item.data.stat.highest.int
                            : rating;
                          const totalGames = performanceData.reduce(
                            (acc, curr) =>
                              acc + (curr.data ? curr.data.perf.nb : 0),
                            0
                          );

                          if (nb === 0) return null; // Do not show the row if there are no games played

                          return (
                            <TableRow key={index}>
                              <TableCell>
                                <Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                  }}
                                >
                                  {(() => {
                                    let icon;
                                    const iconStyle = {
                                      width: 30,
                                      height: 30,
                                      marginRight: 2,
                                    };
                                    switch (item.gameType.toLowerCase()) {
                                      case "ultrabullet":
                                        icon = <BulletIcon sx={iconStyle} />;
                                        break;
                                      case "bullet":
                                        icon = <BulletIcon sx={iconStyle} />;
                                        break;
                                      case "blitz":
                                        icon = <BoltIcon sx={iconStyle} />;
                                        break;
                                      case "rapid":
                                        icon = <RapidIcon sx={iconStyle} />;
                                        break;
                                      case "classical":
                                        icon = <ClassicalIcon sx={iconStyle} />;
                                        break;
                                      case "correspondence":
                                        icon = <DailyIcon sx={iconStyle} />;
                                        break;
                                      default:
                                        icon = <BulletIcon sx={iconStyle} />;
                                    }
                                    return (
                                      <>
                                        {icon}
                                        {item.gameType}
                                      </>
                                    );
                                  })()}
                                </Box>
                              </TableCell>

                              <TableCell>
                                <b>{highest}</b> <br />{" "}
                                <small>
                                  {item.data.stat.highest &&
                                  item.data.stat.highest.at
                                    ? new Date(
                                        item.data.stat.highest.at
                                      ).toLocaleDateString()
                                    : "-"}
                                </small>
                              </TableCell>
                              <TableCell>
                                <b>{rating}</b>
                                <br />{" "}
                                <small>
                                  (
                                  {highest === "-"
                                    ? ""
                                    : rating - highest >= 0
                                    ? "+"
                                    : ""}
                                  {highest === "-" ? 0 : rating - highest})
                                </small>
                              </TableCell>
                              <TableCell>
                                <b>{nb}</b> <br />{" "}
                                <small>
                                  ({((nb / totalGames) * 100).toFixed(1)}%)
                                </small>
                              </TableCell>
                              <TableCell>
                                <b>{wins}</b> <br />{" "}
                                <small>
                                  ({((wins / nb) * 100).toFixed(1)}%)
                                </small>
                              </TableCell>
                              <TableCell>
                                <b>{draws}</b> <br />{" "}
                                <small>
                                  ({((draws / nb) * 100).toFixed(1)}%)
                                </small>
                              </TableCell>
                              <TableCell>
                                <b>{losses}</b> <br />{" "}
                                <small>
                                  ({((losses / nb) * 100).toFixed(1)}%)
                                </small>
                              </TableCell>
                              <TableCell>
                                <div style={{ height: "50px", width: "150px" }}>
                                  <ResponsiveBar
                                    data={[
                                      {
                                        type: "results",
                                        wins: (wins / nb) * 100,
                                        draws: (draws / nb) * 100,
                                        losses: (losses / nb) * 100,
                                      },
                                    ]}
                                    keys={["wins", "draws", "losses"]}
                                    indexBy="type"
                                    margin={{
                                      top: 10,
                                      right: 10,
                                      bottom: 10,
                                      left: 0,
                                    }}
                                    padding={0.3}
                                    layout="horizontal" // Change layout to horizontal
                                    colors={({ id }) => {
                                      if (id === "wins")
                                        return colors.green[500]; // green
                                      if (id === "losses")
                                        return colors.red[300]; // red
                                      if (id === "draws")
                                        return colors.grey[500]; // grey
                                      return "#000";
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableLabel={false}
                                    isInteractive={false}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    theme={{
                                      axis: {
                                        ticks: {
                                          text: {
                                            fontSize: 12,
                                            fill: "#555",
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      </TableBody>
                    </Table>
                  </TableContainer>
                )}
              </div>

              {openingNarrative ? (
                <>
                  <Typography variant="h6" sx={{ pt: 1, pb: 1 }}>
                    Playing as White
                  </Typography>
                  {openingNarrative.white.topMoves.map((move, index) => (
                    <Accordion key={index} mb={4}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Box>
                          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                            {`${getOpeningNameByPgn1(
                              "1. " + move.firstMove
                            )} (1. ${move.firstMove})`}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {`Played ${move.gamesPlayed} Games, Win Rate: ${(
                              (move.wins / move.gamesPlayed) *
                              100
                            ).toFixed(1)}%`}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} pt={2}>
                          {move.topLines.map((line, lineIndex) => (
                            <Grid item xs={12} sm={6} md={4} key={lineIndex}>
                              <Box>
                                <Typography>{`${line.name}`}</Typography>
                                <Typography>{`${line.line}`}</Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {`Played ${line.gamesPlayed} games, Win rate: ${line.winRate}%`}
                                </Typography>
                                <PositionViewer FEN={pgnToFen(line.line)} />
                              </Box>
                            </Grid>
                          ))}
                          {[...Array(3 - move.topLines.length)].map(
                            (_, emptyIndex) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={`empty-${emptyIndex}`}
                              />
                            )
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                  {openingNarrative.white.other.length > 0 && (
                    <Accordion mb={4}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-other-white-content"
                        id="panel-other-white-header"
                      >
                        <Box>
                          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                            Other White Moves
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {`Played ${openingNarrative.white.other.reduce(
                              (total, move) => total + move.gamesPlayed,
                              0
                            )} Games, Win Rate: ${(
                              (openingNarrative.white.other.reduce(
                                (total, move) => total + move.wins,
                                0
                              ) /
                                openingNarrative.white.other.reduce(
                                  (total, move) => total + move.gamesPlayed,
                                  0
                                )) *
                              100
                            ).toFixed(1)}%`}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} pt={2}>
                          {openingNarrative.white.other
                            .flatMap((move) => move.topLines)
                            .sort((a, b) => b.gamesPlayed - a.gamesPlayed)
                            .slice(0, 9)
                            .map((line, lineIndex) => (
                              <Grid item xs={12} sm={6} md={4} key={lineIndex}>
                                <Box>
                                  <Typography>{`${line.name}`}</Typography>
                                  <Typography>{`${line.line}`}</Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {`Played ${line.gamesPlayed} games, Win rate: ${line.winRate}%`}
                                  </Typography>
                                  <PositionViewer FEN={pgnToFen(line.line)} />
                                </Box>
                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )}

                  <Typography variant="h6" sx={{ pt: 1, pb: 1 }}>
                    Playing as Black
                  </Typography>
                  {openingNarrative.black.topMoves.map((move, index) => (
                    <Accordion key={index} mb={4}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`panel${index}-content`}
                        id={`panel${index}-header`}
                      >
                        <Box>
                          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                            {`${getOpeningNameByPgn1(
                              "1. " + move.firstMove
                            )} (1. ${move.firstMove})`}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {`Played ${move.gamesPlayed} Games, Win Rate: ${(
                              (move.wins / move.gamesPlayed) *
                              100
                            ).toFixed(1)}%`}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} pt={2}>
                          {move.topLines.map((line, lineIndex) => (
                            <Grid item xs={12} sm={6} md={4} key={lineIndex}>
                              <Box>
                                <Typography>{`${line.name}`}</Typography>
                                <Typography>{`${line.line}`}</Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {`Played ${line.gamesPlayed} games, Win rate: ${line.winRate}%`}
                                </Typography>
                                <PositionViewer FEN={pgnToFen(line.line)} />
                              </Box>
                            </Grid>
                          ))}
                          {[...Array(3 - move.topLines.length)].map(
                            (_, emptyIndex) => (
                              <Grid
                                item
                                xs={12}
                                sm={6}
                                md={4}
                                key={`empty-${emptyIndex}`}
                              />
                            )
                          )}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  ))}

                  {openingNarrative.black.other.length > 0 && (
                    <Accordion mb={4}>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel-other-black-content"
                        id="panel-other-black-header"
                      >
                        <Box>
                          <Typography variant="h7" sx={{ fontWeight: "bold" }}>
                            Other Black Moves
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {`Played ${openingNarrative.black.other.reduce(
                              (total, move) => total + move.gamesPlayed,
                              0
                            )} Games, Win Rate: ${(
                              (openingNarrative.black.other.reduce(
                                (total, move) => total + move.wins,
                                0
                              ) /
                                openingNarrative.black.other.reduce(
                                  (total, move) => total + move.gamesPlayed,
                                  0
                                )) *
                              100
                            ).toFixed(1)}%`}
                          </Typography>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Grid container spacing={2} pt={2}>
                          {openingNarrative.black.other
                            .flatMap((move) => move.topLines)
                            .sort((a, b) => b.gamesPlayed - a.gamesPlayed)
                            .slice(0, 9)
                            .map((line, lineIndex) => (
                              <Grid item xs={12} sm={6} md={4} key={lineIndex}>
                                <Box>
                                  <Typography>{`${line.name}`}</Typography>
                                  <Typography>{`${line.line}`}</Typography>
                                  <Typography
                                    variant="body2"
                                    color="textSecondary"
                                  >
                                    {`Played ${line.gamesPlayed} games, Win rate: ${line.winRate}%`}
                                  </Typography>
                                  <PositionViewer FEN={pgnToFen(line.line)} />
                                </Box>
                              </Grid>
                            ))}
                        </Grid>
                      </AccordionDetails>
                    </Accordion>
                  )}
                </>
              ) : (
                <></>
              )}
            </Box>
          )
        }

        {
          /* Ratings */ activeTab === 1 && (
            <>
              <Box>
                {publicData && (
                  <Box mt={2} mb={2}>
                    <Box display="flex" flexWrap="wrap" gap={2}>
                      {Object.keys(publicData.perfs)
                        .filter((key) =>
                          [
                            "ultrabullet",
                            "bullet",
                            "blitz",
                            "rapid",
                            "classical",
                            "correspondence",
                          ].includes(key)
                        )
                        .map((key) => {
                          let icon;
                          const iconStyle = { width: 50, height: 50 };
                          switch (key) {
                            case "ultrabullet":
                              icon = <BulletIcon sx={iconStyle} />;
                              break;
                            case "bullet":
                              icon = <BulletIcon sx={iconStyle} />;
                              break;
                            case "blitz":
                              icon = <BoltIcon sx={iconStyle} />;
                              break;
                            case "rapid":
                              icon = <RapidIcon sx={iconStyle} />;
                              break;
                            case "classical":
                              icon = <ClassicalIcon sx={iconStyle} />;
                              break;
                            case "correspondence":
                              icon = <DailyIcon sx={iconStyle} />;
                              break;
                            default:
                              <BulletIcon />;
                          }
                          return (
                            <Box
                              key={key}
                              sx={{
                                minWidth: "150px",
                                display: "flex",
                                alignItems: "center",
                              }}
                              gap={0}
                            >
                              {icon}
                              <Box>
                                <Typography variant="h6">
                                  {key.toUpperCase()}
                                </Typography>
                                <Typography>
                                  <b>
                                    {publicData.perfs[key].rating
                                      .toString()
                                      .toUpperCase()}
                                    {publicData.perfs[key].prov ? "?" : ""}
                                  </b>
                                  {"   "}
                                  <small>
                                    {publicData.perfs[key].games
                                      .toString()
                                      .toUpperCase()}{" "}
                                    GAMES
                                  </small>
                                </Typography>
                              </Box>
                            </Box>
                          );
                        })}
                    </Box>
                  </Box>
                )}
              </Box>
              <Box height={500}>
                {ratingHistory && (
                  <>
                    <Box
                      mt={2}
                      display="flex"
                      flexDirection={isSmallScreen ? "column" : "row"}
                      sx={{ height: "400px", width: "100%" }}
                    >
                      <Box
                        flex="1"
                        marginRight={isSmallScreen ? "0" : "10px"}
                        sx={{ minWidth: 0, height: "400px" }}
                      >
                        <ResponsiveLine
                          data={filteredRatingHistory}
                          xScale={{
                            type: "time",
                            format: "%Y-%m-%d",
                            precision: "day",
                          }}
                          xFormat="time:%Y-%m-%d"
                          margin={{ top: 20, right: 20, bottom: 80, left: 60 }}
                          axisTop={null}
                          axisRight={null}
                          curve="monotoneX"
                          colors={{ scheme: "tableau10" }}
                          axisBottom={{
                            format: "%Y",
                            tickValues: "every year",
                            orient: "bottom",
                            tickSize: 0,
                            tickPadding: 5,
                            tickRotation: 0,
                            legendOffset: 36,
                          }}
                          axisLeft={{
                            orient: "left",
                            tickSize: 0,
                            tickPadding: 10,
                            tickRotation: 0,
                            legendPosition: "middle",
                          }}
                          yScale={{
                            type: "linear",
                            min: "auto",
                            max: "auto",
                            stacked: false,
                            reverse: false,
                          }}
                          pointSize={3}
                          pointColor={{ theme: "background" }}
                          pointBorderWidth={1}
                          pointBorderColor={{ from: "serieColor" }}
                          pointLabelYOffset={-12}
                          useMesh={true}
                          legends={[
                            {
                              anchor: "bottom",
                              direction: "row",
                              justify: false,
                              translateX: -120,
                              translateY: 70,
                              itemsSpacing: 30,
                              itemDirection: "left-to-right",
                              itemWidth: 80,
                              itemHeight: 50,
                              itemOpacity: 0.75,
                              symbolSize: 12,
                              symbolShape: "circle",
                              symbolBorderColor: "rgba(0, 0, 0, .5)",
                            },
                          ]}
                          tooltip={({ point }) => {
                            const pointIdBeforeDot = point.id.split(".")[0];
                            return (
                              <div
                                style={{
                                  background: colors.black[100],
                                  padding: "9px 12px",
                                  border: "1px solid #ccc",
                                }}
                              >
                                <strong>{pointIdBeforeDot}</strong>
                                <br />
                                Date: <strong>{point.data.xFormatted}</strong>
                                <br />
                                Rating: <strong>{point.data.yFormatted}</strong>
                                <br />
                              </div>
                            );
                          }}
                          theme={{
                            grid: {
                              line: {
                                stroke: colors.black[400],
                                strokeWidth: 0.25,
                              },
                            },
                            tooltip: {
                              container: {
                                background: colors.grey[100],
                                color: "white",
                                fontSize: "12px",
                                borderRadius: "2px",
                                boxShadow: "0 3px 9px rgba(0, 0, 0, 0.5)",
                                padding: "5px 9px",
                              },
                            },
                          }}
                        />
                      </Box>
                      <Box
                        flex="1"
                        display="flex"
                        flexDirection="column"
                        sx={{ maxWidth: isSmallScreen ? "100%" : "200px" }}
                      >
                        <Box>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          >
                            <InputLabel>Game Type</InputLabel>
                            <Select
                              value={selectedGameType}
                              onChange={handleGameTypeChange}
                              label="Game Type"
                            >
                              {gameTypes.map((gameType) => (
                                <MenuItem key={gameType} value={gameType}>
                                  {gameType}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Box>
                        <Box>
                          <FormControl
                            variant="outlined"
                            fullWidth
                            margin="normal"
                          >
                            <InputLabel>Date Part</InputLabel>
                            <Select
                              value={timeAggregation}
                              onChange={handleTimeAggregationChange}
                              label="Date Part"
                            >
                              <MenuItem value="Day">Day</MenuItem>
                              <MenuItem value="Week">Week</MenuItem>
                              <MenuItem value="Month">Month</MenuItem>
                            </Select>
                          </FormControl>
                        </Box>
                        <Box>
                          <TextField
                            label="Start Date"
                            type="date"
                            value={startDate}
                            onChange={handleStartDateChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            margin="normal"
                          />
                        </Box>
                        <Box>
                          <TextField
                            label="End Date"
                            type="date"
                            value={endDate}
                            onChange={handleEndDateChange}
                            InputLabelProps={{
                              shrink: true,
                            }}
                            fullWidth
                            margin="normal"
                          />
                        </Box>
                      </Box>
                    </Box>
                  </>
                )}
              </Box>
            </>
          )
        }
        {
          /* Opening Table */ activeTab === 2 && (
            <Box sx={{ marginTop: 2 }}>
              <ToggleButtonGroup
                value={colorView}
                exclusive
                onChange={handleColorViewChange}
                aria-label="color view"
                sx={{ marginBottom: 2 }}
              >
                <ToggleButton value="white" aria-label="white games">
                  White
                </ToggleButton>
                <ToggleButton value="black" aria-label="black games">
                  Black
                </ToggleButton>
              </ToggleButtonGroup>

              <ToggleButtonGroup
                value={view}
                exclusive
                onChange={handleViewChange}
                aria-label="view"
                sx={{ marginBottom: 2, marginLeft: isSmallScreen ? 0 : 2 }}
              >
                <ToggleButton value="results" aria-label="results view">
                  Results
                </ToggleButton>
                <ToggleButton value="opponents" aria-label="opponents view">
                  Opponents
                </ToggleButton>
                <ToggleButton value="phases" aria-label="phases view">
                  Phases
                </ToggleButton>
              </ToggleButtonGroup>

              <TableContainer component={Paper} sx={{ width: "100%" }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell
                        sortDirection={orderBy === "opening" ? order : false}
                        sx={{ width: "400px" }}
                      >
                        <TableSortLabel
                          active={orderBy === "opening"}
                          direction={orderBy === "opening" ? order : "asc"}
                          onClick={() => handleRequestSort("opening")}
                        >
                          Opening
                        </TableSortLabel>
                      </TableCell>
                      <TableCell
                        sortDirection={orderBy === "games" ? order : false}
                      >
                        <TableSortLabel
                          active={orderBy === "games"}
                          direction={orderBy === "games" ? order : "asc"}
                          onClick={() => handleRequestSort("games")}
                        >
                          Games
                        </TableSortLabel>
                      </TableCell>
                      {view === "results" && (
                        <>
                          <TableCell
                            sortDirection={orderBy === "wins" ? order : false}
                          >
                            <TableSortLabel
                              active={orderBy === "wins"}
                              direction={orderBy === "wins" ? order : "asc"}
                              onClick={() => handleRequestSort("wins")}
                            >
                              Wins
                            </TableSortLabel>
                          </TableCell>

                          <TableCell
                            sortDirection={orderBy === "draws" ? order : false}
                          >
                            <TableSortLabel
                              active={orderBy === "draws"}
                              direction={orderBy === "draws" ? order : "asc"}
                              onClick={() => handleRequestSort("draws")}
                            >
                              Draws
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            sortDirection={orderBy === "losses" ? order : false}
                          >
                            <TableSortLabel
                              active={orderBy === "losses"}
                              direction={orderBy === "losses" ? order : "asc"}
                              onClick={() => handleRequestSort("losses")}
                            >
                              Losses
                            </TableSortLabel>
                          </TableCell>
                          <TableCell sx={{ minWidth: "150px" }}>
                            W <small>/</small> D <small>/</small> L
                          </TableCell>
                        </>
                      )}
                      {view === "phases" && (
                        <>
                          <TableCell
                            sortDirection={
                              orderBy === "averageMoves" ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === "averageMoves"}
                              direction={
                                orderBy === "averageMoves" ? order : "asc"
                              }
                              onClick={() => handleRequestSort("averageMoves")}
                            >
                              Avg. Moves
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            sortDirection={
                              orderBy === "averageMiddle" ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === "averageMiddle"}
                              direction={
                                orderBy === "averageMiddle" ? order : "asc"
                              }
                              onClick={() => handleRequestSort("averageMiddle")}
                            >
                              Avg. Opening Moves
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            sortDirection={
                              orderBy === "averageMiddleGame" ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === "averageMiddleGame"}
                              direction={
                                orderBy === "averageMiddleGame" ? order : "asc"
                              }
                              onClick={() =>
                                handleRequestSort("averageMiddleGame")
                              }
                            >
                              Avg. Middle Game Moves
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            sortDirection={
                              orderBy === "averageEndGame" ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === "averageEndGame"}
                              direction={
                                orderBy === "averageEndGame" ? order : "asc"
                              }
                              onClick={() =>
                                handleRequestSort("averageEndGame")
                              }
                            >
                              Avg. End Game Moves
                            </TableSortLabel>
                          </TableCell>
                          <TableCell>Phase</TableCell>
                        </>
                      )}
                      {view === "opponents" && (
                        <>
                          <TableCell
                            sortDirection={
                              orderBy === "scoreVsStronger" ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === "scoreVsStronger"}
                              direction={
                                orderBy === "scoreVsStronger" ? order : "asc"
                              }
                              onClick={() =>
                                handleRequestSort("scoreVsStronger")
                              }
                            >
                              Score vs Stronger (+50)
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            sortDirection={
                              orderBy === "scoreVsEqual" ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === "scoreVsEqual"}
                              direction={
                                orderBy === "scoreVsEqual" ? order : "asc"
                              }
                              onClick={() => handleRequestSort("scoreVsEqual")}
                            >
                              Score vs Equal
                            </TableSortLabel>
                          </TableCell>
                          <TableCell
                            sortDirection={
                              orderBy === "scoreVsWeaker" ? order : false
                            }
                          >
                            <TableSortLabel
                              active={orderBy === "scoreVsWeaker"}
                              direction={
                                orderBy === "scoreVsWeaker" ? order : "asc"
                              }
                              onClick={() => handleRequestSort("scoreVsWeaker")}
                            >
                              Score vs Weaker (-50)
                            </TableSortLabel>
                          </TableCell>
                        </>
                      )}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredTableData.map((row) => {
                      const openingPhase =
                        row.averageMiddle < 1
                          ? row.averageMoves
                          : row.averageMiddle;

                      const middlePhase =
                        row.averageEnd < 1
                          ? (row.averageMoves - row.averageMiddle).toFixed(1)
                          : (row.averageEnd - row.averageMiddle).toFixed(1);

                      const endPhase =
                        row.averageEnd < 1
                          ? 0
                          : (row.averageMoves - row.averageEnd).toFixed(1);

                      return (
                        <TableRow key={row.opening}>
                          <TableCell sx={{ width: "400px" }}>
                            <strong>{row.opening}</strong>
                            <br />
                            <small>
                              {row.eco} <small>|</small> {row.line}
                            </small>
                          </TableCell>
                          <TableCell>
                            <strong>{row.games}</strong>
                            <br />
                            <small>
                              {((row.games / totalGames) * 100).toFixed(1)}%
                            </small>
                          </TableCell>
                          {view === "results" && (
                            <>
                              <TableCell>
                                <strong>{row.wins}</strong>
                                <br />
                                <small>
                                  {((row.wins / row.games) * 100).toFixed(1)}%
                                </small>
                              </TableCell>
                              <TableCell>
                                <strong>{row.draws}</strong>
                                <br />
                                <small>
                                  {((row.draws / row.games) * 100).toFixed(1)}%
                                </small>
                              </TableCell>
                              <TableCell>
                                <strong>{row.losses}</strong>
                                <br />
                                <small>
                                  {((row.losses / row.games) * 100).toFixed(1)}%
                                </small>
                              </TableCell>
                              <TableCell>
                                <div style={{ height: "50px", width: "150px" }}>
                                  <ResponsiveBar
                                    data={[
                                      {
                                        type: "results",
                                        wins: (row.wins / row.games) * 100,
                                        draws: (row.draws / row.games) * 100,
                                        losses: (row.losses / row.games) * 100,
                                      },
                                    ]}
                                    keys={["wins", "draws", "losses"]}
                                    indexBy="type"
                                    margin={{
                                      top: 10,
                                      right: 10,
                                      bottom: 10,
                                      left: 0,
                                    }}
                                    padding={0.3}
                                    layout="horizontal" // Change layout to horizontal
                                    colors={({ id, data }) => {
                                      if (id === "wins")
                                        return colors.green[500]; // green
                                      if (id === "losses")
                                        return colors.red[300]; // red
                                      if (id === "draws")
                                        return colors.grey[500]; // yellow
                                      return "#000";
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableLabel={false}
                                    isInteractive={false}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    theme={{
                                      axis: {
                                        ticks: {
                                          text: {
                                            fontSize: 12,
                                            fill: "#555",
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </>
                          )}
                          {view === "phases" && (
                            <>
                              <TableCell>{row.averageMoves}</TableCell>
                              <TableCell>{openingPhase}</TableCell>
                              <TableCell>{middlePhase}</TableCell>
                              <TableCell>{endPhase}</TableCell>
                              <TableCell>
                                <div style={{ height: "50px", width: "150px" }}>
                                  <ResponsiveBar
                                    data={[
                                      {
                                        type: "phases",
                                        opening: openingPhase,
                                        middle: middlePhase,
                                        end: endPhase,
                                      },
                                    ]}
                                    keys={["opening", "middle", "end"]}
                                    indexBy="type"
                                    margin={{
                                      top: 10,
                                      right: 10,
                                      bottom: 10,
                                      left: 0,
                                    }}
                                    padding={0.3}
                                    layout="horizontal"
                                    colors={({ id, data }) => {
                                      if (id === "opening")
                                        return colors.blue[500];
                                      if (id === "middle")
                                        return colors.orange[500];
                                      if (id === "end")
                                        return colors.green[500];
                                      return "#000";
                                    }}
                                    axisTop={null}
                                    axisRight={null}
                                    axisBottom={null}
                                    axisLeft={null}
                                    enableLabel={false}
                                    isInteractive={false}
                                    animate={true}
                                    motionStiffness={90}
                                    motionDamping={15}
                                    theme={{
                                      axis: {
                                        ticks: {
                                          text: {
                                            fontSize: 12,
                                            fill: "#555",
                                          },
                                        },
                                      },
                                    }}
                                  />
                                </div>
                              </TableCell>
                            </>
                          )}
                          {view === "opponents" && (
                            <>
                              <TableCell>
                                <b
                                  style={{
                                    color:
                                      row.scoreVsStronger > 0
                                        ? colors.green[800]
                                        : row.scoreVsStronger < 0
                                        ? colors.red[500]
                                        : row.scoreVsStronger === 0
                                        ? colors.black[500]
                                        : "black",
                                  }}
                                >
                                  {formatScore(row.scoreVsStronger)}
                                </b>
                                <br />
                                <small>
                                  {row.WinsVsStronger} / {row.DrawsVsStronger} /{" "}
                                  {row.LossesVsStronger}
                                </small>
                              </TableCell>
                              <TableCell>
                                <b
                                  style={{
                                    color:
                                      row.scoreVsEqual > 0
                                        ? colors.green[800]
                                        : row.scoreVsEqual < 0
                                        ? colors.red[500]
                                        : row.scoreVsEqual === 0
                                        ? colors.black[500]
                                        : "black",
                                  }}
                                >
                                  {formatScore(row.scoreVsEqual)}
                                </b>
                                <br />
                                <small>
                                  {row.WinsVsEqual} / {row.DrawsVsEqual} /{" "}
                                  {row.LossesVsEqual}
                                </small>
                              </TableCell>
                              <TableCell>
                                <b
                                  style={{
                                    color:
                                      row.scoreVsWeaker > 0
                                        ? colors.green[800]
                                        : row.scoreVsWeaker < 0
                                        ? colors.red[500]
                                        : row.scoreVsWeaker === 0
                                        ? colors.black[500]
                                        : "black",
                                  }}
                                >
                                  {formatScore(row.scoreVsWeaker)}
                                </b>
                                <br />
                                <small>
                                  {row.WinsVsWeaker} / {row.DrawsVsWeaker} /{" "}
                                  {row.LossesVsWeaker}
                                </small>
                              </TableCell>
                            </>
                          )}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          )
        }
        {
          /* Opening Explorer */ activeTab === 3 && (
            <Box
              display="flex"
              justifyContent="flex-start"
              flexDirection={isSmallScreen ? "column" : "row"}
            >
              <Box
                display="flex"
                flexDirection={isSmallScreen ? "row" : "column"}
                alignItems="flex-start"
                sx={{ width: isSmallScreen ? "100%" : "150px" }}
              >
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  sx={{ mb: 0, mr: isSmallScreen ? 1 : 0 }}
                >
                  <InputLabel>Display</InputLabel>
                  <Select
                    value={vizType}
                    onChange={(e) => setVizType(e.target.value)}
                    label="Display"
                  >
                    <MenuItem value="Circles">Circles</MenuItem>
                    <MenuItem value="Sunburst">Sunburst</MenuItem>
                    <MenuItem value="TreeView">Tree View</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  sx={{ mb: 0, mr: isSmallScreen ? 1 : 0 }}
                >
                  <InputLabel>Colour</InputLabel>
                  <Select
                    value={selectedRootNode}
                    onChange={(e) => setSelectedRootNode(e.target.value)}
                    label="Display"
                  >
                    <MenuItem value="All">All</MenuItem>
                    <MenuItem value="White">White</MenuItem>
                    <MenuItem value="Black">Black</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  sx={{ marginBottom: 1 }}
                >
                  <InputLabel>Moves</InputLabel>
                  <Select
                    value={maxMoves}
                    onChange={handleMaxMovesChange}
                    label="Moves"
                  >
                    {[...Array(10).keys()].map((_, index) => (
                      <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
              <div
                style={{
                  width: isSmallScreen ? "100%" : "600px",
                  height: isSmallScreen ? "400px" : "600px",
                }}
              >
                {vizType === "Circles" ? (
                  <ResponsiveCirclePacking
                    data={getFilteredCirclePackingData(
                      circlePackingData,
                      selectedRootNode
                    )}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    id="name"
                    value="value"
                    colors={(node) => {
                      if (node.depth === 0) {
                        return "#ffffff";
                      }
                      const winRate = node.data.wins / node.value;
                      const green = Math.min(255, Math.floor(255 * winRate));
                      const red = Math.min(
                        255,
                        Math.floor(255 * (1 - winRate))
                      );
                      return `rgb(${red}, ${green}, 0)`;
                    }}
                    padding={10}
                    enableLabels={false}
                    labelsSkipRadius={10}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    borderWidth={1}
                    borderColor={(node) => {
                      if (node.id === "Black" || node.id === "White") {
                        return "black";
                      }
                      return `rgba(0, 0, 0, 0.5)`;
                    }}
                    fill={[
                      {
                        match: { id: "Black" },
                        id: "blackPattern",
                      },
                      {
                        match: { id: "White" },
                        id: "greyPattern",
                      },
                    ]}
                    defs={[
                      {
                        id: "blackPattern",
                        type: "patternLines",
                        color: "#BBBBBB",
                        background: "#FFFFFF",
                        rotation: -45,
                        lineWidth: 5,
                        spacing: 8,
                      },
                      {
                        id: "greyPattern",
                        type: "patternLines",
                        color: "#EFEFEF",
                        background: "#FFFFFF",
                        rotation: -45,
                        lineWidth: 5,
                        spacing: 8,
                      },
                    ]}
                    tooltip={({ id, value, data }) => (
                      <div
                        style={{
                          padding: "12px",
                          color: colors.black[900],
                          background: colors.black[100],
                        }}
                      >
                        <strong>{id}</strong>
                        <br />
                        {value} games ({((value / totalGames) * 100).toFixed(2)}
                        %)
                        <br />
                        {data.wins} wins (
                        {((data.wins / value) * 100).toFixed(2)}% win rate)
                      </div>
                    )}
                  />
                ) : vizType === "Sunburst" ? (
                  <ResponsiveSunburst
                    data={getFilteredCirclePackingData(
                      circlePackingData,
                      selectedRootNode
                    )}
                    margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
                    id="name"
                    value="value"
                    colors={(node) => {
                      if (node.depth === 0) {
                        return "#ffffff";
                      }
                      const winRate = node.data.wins / node.value;
                      const green = Math.min(255, Math.floor(255 * winRate));
                      const red = Math.min(
                        255,
                        Math.floor(255 * (1 - winRate))
                      );
                      return `rgb(${red}, ${green}, 0)`;
                    }}
                    inheritColorFromParent={false}
                    borderWidth={1}
                    borderColor={(node) => {
                      if (node.id === "Black" || node.id === "White") {
                        return "black";
                      }
                      return `rgba(0, 0, 0, 0.5)`;
                    }}
                    enableLabels={false}
                    labelsSkipRadius={10}
                    labelTextColor={{
                      from: "color",
                      modifiers: [["darker", 2]],
                    }}
                    defs={[
                      {
                        id: "blackPattern",
                        type: "patternLines",
                        color: "#BBBBBB",
                        background: "#FFFFFF",
                        rotation: -45,
                        lineWidth: 5,
                        spacing: 8,
                      },
                      {
                        id: "greyPattern",
                        type: "patternLines",
                        color: "#EFEFEF",
                        background: "#FFFFFF",
                        rotation: -45,
                        lineWidth: 5,
                        spacing: 8,
                      },
                    ]}
                    fill={[
                      {
                        match: { id: "Black" },
                        id: "blackPattern",
                      },
                      {
                        match: { id: "White" },
                        id: "greyPattern",
                      },
                    ]}
                    tooltip={({ id, value, data }) => (
                      <div
                        style={{
                          padding: "12px",
                          color: colors.black[900],
                          background: colors.black[100],
                        }}
                      >
                        <strong>{id}</strong>
                        <br />
                        {value} games ({((value / totalGames) * 100).toFixed(2)}
                        %)
                        <br />
                        {data.wins} wins (
                        {((data.wins / value) * 100).toFixed(2)}% win rate)
                      </div>
                    )}
                  />
                ) : (
                  /** Tree View */
                  <Box sx={{ p: 2 }}>
                    <Tree
                      showLine
                      treeData={[
                        getFilteredTreeData(treeData, selectedRootNode),
                      ]} // Ant Design expects an array of nodes
                      titleRender={customTitleRender}
                    />
                  </Box>
                )}
              </div>
            </Box>
          )
        }
        {
          /* User Activity */ activeTab === 4 && (
            <>
              <ToggleButtonGroup
                value={activityView}
                exclusive
                onChange={handleActivityViewChange}
                aria-label="view"
                sx={{ mb: 2, mt: 2 }}
              >
                <ToggleButton value="calendar" aria-label="results view">
                  Calendar
                </ToggleButton>
                <ToggleButton value="gamesperhour" aria-label="opponents view">
                  Games Per Hour
                </ToggleButton>
                <ToggleButton value="gamesperday" aria-label="phases view">
                  Games Per Day
                </ToggleButton>
              </ToggleButtonGroup>

              {activityView === "calendar" && (
                <Box
                  width={isSmallScreen ? "100%" : "900px"}
                  height={`${getUniqueYears(calendarData) * 200}px`}
                >
                  <ResponsiveCalendar
                    data={calendarData}
                    from={getMinMaxDates(calendarData).minDate}
                    to={getMinMaxDates(calendarData).maxDate}
                    colors={[
                      "#cce4f6",
                      "#99c9ed",
                      "#66afe5",
                      "#3384d4",
                      "#0064c9",
                    ]}
                    emptyColor={colors.black[200]}
                    margin={{ top: 40, right: 40, bottom: 40, left: 40 }}
                    yearSpacing={40}
                    monthBorderColor={colors.black[100]}
                    dayBorderWidth={0.5}
                    dayBorderColor={colors.black[100]}
                    tooltip={({ day, value, data }) => {
                      const winPercentage =
                        data && data.wins && data.value
                          ? ((data.wins / data.value) * 100).toFixed(2)
                          : 0;
                      return (
                        <div
                          style={{
                            background: colors.black[100],
                            padding: "5px 10px",
                            border: "1px solid #ccc",
                            color: colors.black[900],
                          }}
                        >
                          <strong>{day}</strong>
                          <br />
                          {value ? `${value} games` : "No games"}
                          <br />
                          {value ? `${data.wins} wins (${winPercentage}%)` : ""}
                        </div>
                      );
                    }}
                  />
                </Box>
              )}
              {activityView === "gamesperhour" && (
                <Box width={isSmallScreen ? "100%" : "900px"} height="300px">
                  <ResponsiveBar
                    data={gamesPerHour}
                    keys={[
                      "ultrabullet",
                      "bullet",
                      "blitz",
                      "rapid",
                      "classical",
                      "correspondence",
                    ]}
                    indexBy="hour"
                    margin={{ top: 20, right: 200, bottom: 20, left: 40 }} // Adjust right margin to accommodate the legend
                    padding={0.3}
                    colors={{ scheme: "nivo" }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 0,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: "middle",
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 0,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    label={false}
                    tooltip={({ id, value, indexValue }) => (
                      <div
                        style={{
                          padding: "12px",
                          background: colors.grey[100],
                          color: colors.black[900],
                          borderRadius: "3px",
                        }}
                      >
                        <strong>Hour {indexValue}</strong>
                        <br />
                        {value} {id.charAt(0).toUpperCase() + id.slice(1)} Games
                      </div>
                    )}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: isSmallScreen ? "bottom" : "right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </Box>
              )}
              {activityView === "gamesperday" && (
                <Box width={isSmallScreen ? "100%" : "900px"} height="300px">
                  <ResponsiveBar
                    data={gamesPerWeekDay}
                    keys={[
                      "ultrabullet",
                      "bullet",
                      "blitz",
                      "rapid",
                      "classical",
                      "correspondence",
                    ]}
                    indexBy="day"
                    margin={{ top: 20, right: 200, bottom: 20, left: 40 }} // Adjust right margin to accommodate the legend
                    padding={0.3}
                    colors={{ scheme: "nivo" }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                      tickSize: 0,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: "middle",
                      legendOffset: 32,
                    }}
                    axisLeft={{
                      tickSize: 0,
                      tickPadding: 5,
                      tickRotation: 0,
                      legendPosition: "middle",
                      legendOffset: -40,
                    }}
                    label={false}
                    tooltip={({ id, value, indexValue }) => (
                      <div
                        style={{
                          padding: "12px",
                          background: colors.grey[100],
                          color: colors.black[900],
                          borderRadius: "3px",
                        }}
                      >
                        <strong>{indexValue}</strong>
                        <br />
                        {value} {id.charAt(0).toUpperCase() + id.slice(1)} Games
                      </div>
                    )}
                    legends={[
                      {
                        dataFrom: "keys",
                        anchor: "right",
                        direction: "column",
                        justify: false,
                        translateX: 120,
                        translateY: 0,
                        itemsSpacing: 2,
                        itemWidth: 100,
                        itemHeight: 20,
                        itemDirection: "left-to-right",
                        itemOpacity: 0.85,
                        symbolSize: 20,
                        effects: [
                          {
                            on: "hover",
                            style: {
                              itemOpacity: 1,
                            },
                          },
                        ],
                      },
                    ]}
                  />
                </Box>
              )}
            </>
          )
        }
      </Box>
    </Box>
  );
}

export default UserScout;
