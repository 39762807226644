import React, { useState, useEffect } from "react";
import { Box, Button, Typography, useTheme, Paper } from "@mui/material";
import {
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  MouseSensor,
  TouchSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  useSortable,
  horizontalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import AnagramsHelp from "../../../help/AnagramsHelp";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";

import { Helmet } from "react-helmet";
import { chessterms } from "../../../data/chessterms/chessterms";
import { useUser } from "../../../context/UserContext";
import {
  incrementGameProperty,
  updateUserData,
} from "../../../features/Firestore";
import { trackEvent } from "../../../config/ga";
import { useShare } from "../../../context/ShareContext";

const SortableItem = ({ id, letter }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: "10px",
    textAlign: "center",
    width: "40px",
    cursor: "pointer",
    margin: "0px",
    background: isDragging ? "#f0f0f0" : "#ffffff",
    borderRadius: "4px",
    boxShadow: "0 0 5px rgba(0, 0, 0, 0.2)",
    textTransform: "uppercase", // Ensure text is displayed in uppercase
    color: "#333333",
  };

  return (
    <Paper ref={setNodeRef} style={style} {...attributes} {...listeners}>
      {letter.toUpperCase()} {/* Ensure letter is uppercase */}
    </Paper>
  );
};

function Anagrams() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setShareData } = useShare();

  const [selectedPhrase, setSelectedPhrase] = useState();
  const [randomizedPhrase, setRandomizedPhrase] = useState([]);
  const [completed, setComplete] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [hint, setHint] = useState("");
  const [showGiveUp, setShowGiveUp] = useState(false);
  const [gameStarted, setGameStarted] = useState(false); // New state for gameStarted
  const { userData, userRef } = useUser();

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 10 } }),
    useSensor(TouchSensor, { activationConstraint: { distance: 10 } })
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;
    if (active.id !== over.id) {
      const oldIndex = randomizedPhrase.indexOf(active.id);
      const newIndex = randomizedPhrase.indexOf(over.id);
      const newArray = arrayMove(randomizedPhrase, oldIndex, newIndex);
      setRandomizedPhrase(newArray);

      // Extract the original letters from the IDs
      const joinedPhrase = newArray
        .map((id) => id[0])
        .join("")
        .replace(/\d+/g, "")
        .replace(/\s+/g, " ");
      setComplete(joinedPhrase === selectedPhrase.Term.toUpperCase());

      if (joinedPhrase === selectedPhrase.Term.toUpperCase()) {
        trackEvent("Games", "Game-Completed", "Anagram");
        incrementGameProperty("Anagrams-Completed");
        if (userData) {
          userData.Puzzles.Anagrams.Completed =
            (userData.Puzzles.Anagrams.Completed || 0) + 1;
          updateUserData(userRef, userData);
        }
      }
    }
  };

  const handleNewGame = () => {
    const filteredChessterms = chessterms.filter((term) => term.Length > 4);
    const randomNumber = Math.floor(Math.random() * filteredChessterms.length);
    const newSelectedPhrase = filteredChessterms[randomNumber];
    setSelectedPhrase(newSelectedPhrase);

    if (newSelectedPhrase.Type === "T") {
      setHint("Chess Term");
    } else if (
      newSelectedPhrase.Type === "P" ||
      newSelectedPhrase.Type === "W"
    ) {
      setHint("Chess Player");
    } else if (newSelectedPhrase.Type === "E") {
      setHint("Chess Engine");
    } else if (newSelectedPhrase.Type === "O") {
      setHint("Chess Opening");
    }

    setShowHint(false);
    setShowGiveUp(false);
    setComplete(false);
    setGameStarted(true); // Set gameStarted to true when a new game starts
    const phraseArray = newSelectedPhrase.Term.split("");
    setRandomizedPhrase(randomizeString(phraseArray));

    // GA Tracking
    trackEvent("Games", "Anagrams-Play", "Anagrams");
    // Internal Tracking
    incrementGameProperty("Anagrams-Play");
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.Anagrams) {
        userData.Puzzles.Anagrams.Played =
          (userData.Puzzles.Anagrams.Played || 0) + 1;
      } else {
        userData.Puzzles.Anagrams = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  const handleShowHint = () => {
    setShowHint(true);
    setShowGiveUp(true);
  };

  const handleGiveUp = () => {
    const phraseArray = selectedPhrase.Term.split("");
    setRandomizedPhrase(
      phraseArray.map((char, index) => char.toUpperCase() + index)
    );
    setComplete(true);

    // GA Tracking
    trackEvent("Games", "Anagrams-Failed", "Anagrams");
    // Internal Tracking
    incrementGameProperty("Anagrams-Failed");
    if (userData) {
      userData.Puzzles.Anagrams.Failed =
        (userData.Puzzles.Anagrams.Failed || 0) + 1;
      updateUserData(userRef, userData);
    }
  };

  function randomizeString(array) {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array.map((char, index) => char.toUpperCase() + index); // Ensure uppercase and unique IDs
  }

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/anagrams",
      title: "Chessboard Magic - Chess Anagrams",
      description:
        "Form words from scrambled letters related to chess terms, players, openings, or engines. Fast-paced and fun!",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  return (
    <Box>
      <ContentHeader
        title="Chess Anagrams"
        subtitle="Form words from scrambled letters related to chess terms, players, openings, or engines. Fast-paced and fun!"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Chess Anagrams</title>
        <meta
          name="description"
          content="Form words from scrambled letters related to chess terms, players, openings, or engines. Fast-paced and fun!"
        />
        <meta property="og:title" content="Chess Anagrams" />
        <meta
          property="og:description"
          content="Form words from scrambled letters related to chess terms, players, openings, or engines. Fast-paced and fun!"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/anagrams.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/anagrams`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Anagrams" />
        <meta
          name="twitter:description"
          content="Form words from scrambled letters related to chess terms, players, openings, or engines. Fast-paced and fun!"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/anagrams.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.black[900] }}
            />
          }
        >
          Play
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.black[900] }} />}
        >
          Help
        </Button>
        {!showGiveUp && (
          <Button
            variant="contained"
            onClick={handleShowHint}
            startIcon={
              <VisibilityRoundedIcon style={{ color: colors.black[900] }} />
            }
            disabled={!gameStarted} // Disable button when game is not started
          >
            Hint
          </Button>
        )}
        {showGiveUp && (
          <Button
            variant="contained"
            onClick={handleGiveUp}
            startIcon={
              <HighlightOffRoundedIcon style={{ color: colors.red[500] }} />
            }
            disabled={!gameStarted} // Disable button when game is not started
          >
            Quit
          </Button>
        )}
        <HelpModal
          open={open}
          onClose={handleClose}
          title="Chess Anagrams Help"
          content={<AnagramsHelp />}
        ></HelpModal>
        {showHint && (
          <Typography sx={{ paddingTop: "5px" }}>
            <i>Hint: {hint}</i>
          </Typography>
        )}
      </Box>

      <Box
        sx={{
          padding: "10px 0px 20px 0px",
        }}
      >
        <DndContext
          sensors={sensors}
          collisionDetection={closestCenter}
          onDragEnd={handleDragEnd}
        >
          <SortableContext
            items={randomizedPhrase}
            strategy={horizontalListSortingStrategy}
          >
            <Box
              id="anagram"
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                padding: "10px 0",
              }}
            >
              {randomizedPhrase.map((id) => (
                <SortableItem key={id} id={id} letter={id[0]} />
              ))}
            </Box>
          </SortableContext>
        </DndContext>
      </Box>
      <Box
        sx={{
          p: "0px 0px 0px 0px",
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "column",
            md: "row",
          },
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        {completed && (
          <Box
            id="results"
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 1,
              alignItems: "flex-start",
              maxWidth: "600px",
              paddingBottom: "20px", // Add padding to the Description
            }}
          >
            <Typography>{selectedPhrase.Description}</Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

export default Anagrams;
