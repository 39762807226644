import React, { useState, useEffect, useCallback } from "react";
import { useParams } from "react-router-dom";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import GuessTheMoveHelp from "../../../help/GuessTheMoveHelp";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import MemoryRoundedIcon from "@mui/icons-material/MemoryRounded";
import { Helmet } from "react-helmet";
import { guesstheeval } from "../../../data/guesstheeval/guesstheeval";
import { trackEvent } from "../../../config/ga";
import {
  incrementGameProperty,
  updateUserData,
} from "../../../features/Firestore";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";

function GuessTheMove() {
  const [game, setGame] = useState(new Chess());
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [gameover, setGameover] = useState(true);
  const [gameEval, setGameEval] = useState(0);
  const [toPlay, setToPlay] = useState("");
  const [resultMessage, setResultMessage] = useState("");
  const [modernMoves, setModernMoves] = useState([]);
  const [attemptsLeft, setAttemptsLeft] = useState(3); // Track number of attempts
  const [piecesDraggable, setPiecesDraggable] = useState(true); // Control if pieces are draggable
  const { gameId } = useParams();
  const [fen, setFen] = useState("");
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();

  const handleNewGame = useCallback(
    (gameId) => {
      let game;
      setGameover(false);
      const numericGameId = Number(gameId);
      if (
        !isNaN(numericGameId) &&
        numericGameId >= 0 &&
        numericGameId < guesstheeval.length
      ) {
        game = guesstheeval[numericGameId];
      } else {
        // Otherwise, pick a random game
        let randomNumber = getRandomNumber(guesstheeval.length);
        game = guesstheeval[randomNumber];
      }

      setFen(game.FEN);
      const newGame = new Chess(game.FEN);
      const moves = game.MOVES.split(" ");
      const newModernMoves = [];

      moves.forEach((move) => {
        if (move === "e1h1") {
          newModernMoves.push("O-O");
          newGame.move("O-O");
        } else if (move === "e8h8") {
          newModernMoves.push("O-O");
          newGame.move("O-O");
        } else if (move === "e1a1") {
          newModernMoves.push("O-O-O");
          newGame.move("O-O-O");
        } else if (move === "e8a8") {
          newModernMoves.push("O-O-O");
          newGame.move("O-O-O");
        } else {
          const result = newGame.move(move);
          if (result) {
            newModernMoves.push(result.san); // Get the move in modern notation (SAN)
          }
        }
      });

      setModernMoves(newModernMoves);

      // Reset the game back to the original FEN
      const originalGame = new Chess(game.FEN);

      const parts = game.FEN.split(" ");
      const firstLetterAfterSpace = parts[1];
      const toPlay = firstLetterAfterSpace === "b" ? "Black" : "White";
      setToPlay(toPlay);

      setGameEval(typeof game.EVAL === "number" ? game.EVAL / 100 : game.EVAL);

      setGame(originalGame); // Update the game state with the new game
      setResultMessage("");
      setAttemptsLeft(3); // Reset attempts
      setPiecesDraggable(true); // Allow pieces to be draggable again for a new game

      //GA Tracking
      trackEvent("Games", "Game-Play", "Guess The Move");
      // Internal Tracking
      incrementGameProperty("GuessTheMove-Play");
      if (userData) {
        if (!userData.Puzzles) {
          userData.Puzzles = {};
        }
        if (userData.Puzzles.GuessTheMove) {
          userData.Puzzles.GuessTheMove.Played =
            (userData.Puzzles.GuessTheMove.Played || 0) + 1;
        } else {
          userData.Puzzles.GuessTheMove = {
            Played: 1,
            Completed: 0,
          };
        }
        updateUserData(userRef, userData);
      }
    },
    [userData, userRef]
  );

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/GuessTheMove",
      title: "Chessboard Magic - Guess The Move",
      description:
        "A position appears—can you outguess the engine's next move? Test your chess instincts and see if you can match its strategy!",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  useEffect(() => {
    if (gameId) {
      handleNewGame(gameId);
    }
  }, [gameId, handleNewGame]);

  function getRandomNumber(n) {
    return Math.floor(Math.random() * n);
  }

  const handlePieceDrop = (sourceSquare, targetSquare) => {
    // Get the piece on the source square
    const piece = game.get(sourceSquare);

    // Check if the piece color matches the current player (toPlay)
    if (
      piece &&
      ((toPlay === "White" && piece.color !== "w") ||
        (toPlay === "Black" && piece.color !== "b"))
    ) {
      // If the piece does not belong to the current player, ignore the move
      return false;
    }

    const move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q", // Handle pawn promotion
    });

    // If the move is invalid, return false to reset the piece
    if (move === null) return false;

    // Check if the move matches the next move in modernMoves
    const correctMove = modernMoves[game.history().length - 1];
    if (move.san === correctMove) {
      setResultMessage("Correct");
      setPiecesDraggable(false); // Disable dragging if the correct move is made
      setGameover(true);
    } else {
      setAttemptsLeft(attemptsLeft - 1);
      setResultMessage(`Incorrect! ${attemptsLeft - 1} attempts left.`);
      if (attemptsLeft - 1 === 0) {
        // Reset after 3 incorrect attempts
        setResultMessage(
          `No more attempts. The correct move was ${correctMove}`
        );
        setGame(new Chess(fen)); // Reset to original FEN
        setGameover(true);
      } else {
        setGame(new Chess(fen)); // Reset to original FEN after each incorrect move
      }
    }
    return true;
  };

  const handleMoveClickReset = () => {
    const newGame = new Chess(fen); // Start with the current game position
    setGame(newGame); // Update the game state with the new game position
  };

  const handleMoveClick = (moveIndex) => {
    const newGame = new Chess(fen); // Start with the current game position
    for (let i = 0; i <= moveIndex; i++) {
      newGame.move(modernMoves[i]);
    }
    setGame(newGame); // Update the game state with the new game position
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ContentHeader
        title="Guess The Move"
        subtitle="A position appears—can you outguess the engine's next move? Test your chess instincts and see if you can match its strategy!"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Guess The Move</title>
        <meta
          name="description"
          content="A position appears—can you outguess the engine's next move? Test your chess instincts and see if you can match its strategy!."
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.black[900] }}
            />
          }
        >
          Play
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          startIcon={<HelpOutlineIcon style={{ color: colors.black[900] }} />}
        >
          Help
        </Button>
        <HelpModal
          open={open}
          onClose={handleClose}
          title="Guess The Move Help"
          content={<GuessTheMoveHelp />}
        ></HelpModal>
      </Box>
      <Box
        sx={{
          p: "20px 0px 0px 0px",
          display: "flex",
          flexDirection: { xs: "column", sm: "column", md: "row" },
          alignItems: "flex-start",
          width: "100%",
        }}
      >
        <div
          id="chessboard"
          style={{
            width: "100%",
            maxWidth: "500px",
            padding: "0px 10px 10px 0px",
            boxSizing: "border-box",
          }}
        >
          <Chessboard
            position={game.fen()}
            onPieceDrop={handlePieceDrop} // Restricting moves to valid ones
            arePiecesDraggable={piecesDraggable} // Enable or disable piece dragging based on correctness
            customLightSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].lightSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .lightSquarePattern,
            }}
            customDarkSquareStyle={{
              backgroundColor:
                themeColors[userData?.theme || "Modern Minimal"].darkSquare,
              backgroundImage:
                themeColors[userData?.theme || "Modern Minimal"]
                  .darkSquarePattern,
            }}
            customPieces={pieceSets[userData?.pieceset || "Maestro"]}
          />
        </div>
        <div
          id="results"
          style={{ flex: 1, overflow: "auto", padding: "0px 0px 10px 0px" }}
        >
          {toPlay && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                margin: "10px 0px 10px 0px",
              }}
            >
              <Typography component="span" style={{ marginBottom: "0px" }}>
                {toPlay} to Play
              </Typography>
              {resultMessage && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    {resultMessage === "Correct" ? (
                      <CheckCircleOutlineIcon
                        style={{
                          color: "green",
                          marginRight: "5px",
                          fontSize: "32px",
                        }}
                      />
                    ) : resultMessage.startsWith("N") ||
                      resultMessage.startsWith("I") ? (
                      <ErrorOutlineIcon
                        style={{
                          color: "red",
                          marginRight: "5px",
                          fontSize: "32px",
                        }}
                      />
                    ) : null}

                    <Typography component="span">
                      {resultMessage}!{" "}
                      {gameover && (
                        <>
                          The evaluation is <b>{gameEval}</b>
                        </>
                      )}
                    </Typography>
                  </div>
                  {gameover && (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "wrap",
                      }}
                    >
                      <MemoryRoundedIcon
                        style={{
                          color: colors.grey[700],
                          marginRight: "5px",
                          fontSize: "32px",
                          display: "flex",
                          flexWrap: "wrap",
                        }}
                      />
                      <Typography component="span">
                        <span
                          onClick={() => handleMoveClickReset()}
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                            marginRight: "5px",
                          }}
                        >
                          Reset
                        </span>
                        {modernMoves
                          .filter((_, index) => index < 8)
                          .map((move, index) => (
                            <span
                              key={index}
                              onClick={() => handleMoveClick(index)}
                              style={{
                                cursor: "pointer",
                                textDecoration: "underline",
                                marginRight: "5px",
                              }}
                            >
                              {move}
                            </span>
                          ))}
                      </Typography>
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </Box>
    </Box>
  );
}

export default GuessTheMove;
