import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import { useShare } from "../../../context/ShareContext";

function CompetitiveRulesOfPlay() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/competitiverulesofplay",
      title: "Chessboard Magic - Competitive Rules of Play",
      description:
        "Competitive chess rules ensure fair play, covering player conduct, piece movement, clock use, and dispute resolution.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  return (
    <Box>
      <ContentHeader
        title="FIDE Competitive Rules Of Play"
        subtitle="(January 2023) Competitive chess rules ensure fair play, covering player conduct, piece movement, clock use, and dispute resolution."
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[6]}
      />
      <Helmet>
        <title>Competitive Rules Of Play</title>
        <meta
          name="description"
          content="Competitive chess rules ensure fair play, covering player conduct, piece movement, clock use, and dispute resolution."
        />
        <meta property="og:title" content="Competitive Rules of Play" />
        <meta
          property="og:description"
          content="Competitive chess rules ensure fair play, covering player conduct, piece movement, clock use, and dispute resolution."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/competitiverulesofplay.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/competitiverulesofplay`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Competitive Rules of Play" />
        <meta
          name="twitter:description"
          content="Competitive chess rules ensure fair play, covering player conduct, piece movement, clock use, and dispute resolution."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/competitiverulesofplay.png`}
        />
      </Helmet>
      <Box sx={{ maxWidth: "600px" }}>
        <Typography variant="h6" gutterBottom>
          Article 6: The Chessclock
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.1</strong> ‘Chessclock’ means a clock with two time
          displays, connected to each other in such a way that only one of them
          can run at a time. ‘Clock’ in the Laws of Chess means one of the two
          time displays. Each time display has a ‘flag’. ‘Flag-fall’ means the
          expiration of the allotted time for a player.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.2</strong> Handling the chessclock:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.2.1</strong> During the game each player, having made
          his/her move on the chessboard, shall pause his/her own clock and
          start his/her opponent’s clock (that is to say, he/she shall press
          his/her clock). This ‘completes’ the move. A move is also completed
          if:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>6.2.1.1</strong> the move ends the game (see Articles 5.1.1,
          5.2.1, 5.2.2, 9.2.1, 9.6.1 and 9.6.2), or
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>6.2.1.2</strong> the player has made his/her next move, when
          his/her previous move was not completed.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.2.2</strong> A player must be allowed to pause his/her clock
          after making his/her move, even after the opponent has made his/her
          next move. The time between making the move on the chessboard and
          pressing the clock is regarded as part of the time allotted to the
          player.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.2.3</strong> A player must press his/her clock with the same
          hand with which he/she made his/her move. It is forbidden for a player
          to keep his/her finger on the clock or to ‘hover’ over it.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.2.4</strong> The players must handle the chessclock
          properly. It is forbidden to press it forcibly, to pick it up, to
          press the clock before moving or to knock it over. Improper clock
          handling shall be penalised in accordance with Article 12.9.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.2.5</strong> Only the player whose clock is running is
          allowed to adjust the pieces.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.2.6</strong> If a player is unable to use the clock, an
          assistant, who must be acceptable to the arbiter, may be provided by
          the player to perform this operation. His/Her clock shall be adjusted
          by the arbiter in an equitable way. This adjustment of the clock shall
          not apply to the clock of a player with a disability.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.3</strong> Allotted time:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.3.1</strong> When using a chessclock, each player must
          complete a minimum number of moves or all moves in an allotted period
          of time including any additional amount of time added with each move.
          All these must be specified in advance.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.3.2</strong> The time saved by a player during one period is
          added to his/her time available for the next period, where applicable.
          In the time-delay mode both players receive an allotted ‘main thinking
          time’. Each player also receives a ‘fixed extra time’ with every move.
          The countdown of the main thinking time only commences after the fixed
          extra time has expired. Provided the player presses his/her clock
          before the expiration of the fixed extra time, the main thinking time
          does not change, irrespective of the proportion of the fixed extra
          time used.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.4</strong> Immediately after a flag falls, the requirements
          of Article 6.3.1 must be checked.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.5</strong> Before the start of the game the arbiter shall
          decide where the chessclock is placed.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.6</strong> At the time determined for the start of the game
          White’s clock is started.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.7</strong> Default time:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.7.1</strong> The regulations of an event shall specify a
          default time in advance. If the default time is not specified, then it
          is zero. Any player who arrives at the chessboard after the default
          time shall lose the game unless the arbiter decides otherwise.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.7.2</strong> If the regulations of an event specify that the
          default time is not zero and if neither player is present initially,
          White shall lose all the time that elapses until he/she arrives,
          unless the regulations of an event specify or the arbiter decides
          otherwise.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.8</strong> A flag is considered to have fallen when the
          arbiter observes the fact or when either player has made a valid claim
          to that effect.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.9</strong> Except where one of Articles 5.1.1, 5.1.2, 5.2.1,
          5.2.2, 5.2.3 applies, if a player does not complete the prescribed
          number of moves in the allotted time, the game is lost by that player.
          However, the game is drawn if the position is such that the opponent
          cannot checkmate the player’s king by any possible series of legal
          moves.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.10</strong> Chessclock setting:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.10.1</strong> Every indication given by the chessclock is
          considered to be conclusive in the absence of any evident defect. A
          chessclock with an evident defect shall be replaced by the arbiter,
          who shall use his/her best judgement when determining the times to be
          shown on the replacement chessclock.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.10.2</strong> If during a game it is found that the setting
          of either or both clocks is incorrect, either player or the arbiter
          shall stop the chessclock immediately. The arbiter shall install the
          correct setting and adjust the times and move-counter, if necessary.
          He/She shall use his/her best judgement when determining the clock
          settings.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.11</strong> Pausing the chessclock:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.11.1</strong> If the game needs to be interrupted, the
          arbiter shall pause the chessclock.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.11.2</strong> A player may pause the chessclock only in
          order to seek the arbiter’s assistance, for example when promotion has
          taken place and the piece required is not available.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.11.3</strong> The arbiter shall decide when the game
          restarts.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.11.4</strong> If a player pauses the chessclock in order to
          seek the arbiter’s assistance, the arbiter shall determine whether the
          player had any valid reason for doing so. If the player has no valid
          reason for pausing the chessclock, the player shall be penalised in
          accordance with Article 12.9.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>6.12</strong> Displays in the playing hall:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.12.1</strong> Screens, monitors, or demonstration boards
          showing the current position on the chessboard, the moves and the
          number of moves made/completed, and clocks which also show the number
          of moves, are allowed in the playing hall.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>6.12.2</strong> The player may not make a claim relying only
          on information shown in this manner.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Article 7: Irregularities
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.1</strong> If an irregularity occurs and the pieces have to
          be restored to a previous position, the arbiter shall use his/her best
          judgement to determine the times to be shown on the chessclock. This
          includes the right not to change the clock times. He/She shall also,
          if necessary, adjust the clock’s move-counter.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.2</strong> Incorrect piece placement:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.2.1</strong> If during a game it is found that the initial
          position of the pieces was incorrect, the game shall be cancelled and
          a new game shall be played.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.2.2</strong> If during a game it is found that the
          chessboard has been placed contrary to Article 2.1, the game shall
          continue but the position reached must be transferred to a correctly
          placed chessboard.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.3</strong> Incorrect colour assignment:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          If a game has started with colours reversed then, if less than 10
          moves have been made by both players, it shall be discontinued and a
          new game played with the correct colours. After 10 moves or more, the
          game shall continue.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.4</strong> Displaced pieces:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.4.1</strong> If a player displaces one or more pieces,
          he/she shall re-establish the correct position in his/her own time.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.4.2</strong> If necessary, either the player or his/her
          opponent shall pause the chessclock and ask for the arbiter’s
          assistance.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.4.3</strong> The arbiter may penalise the player who
          displaces the pieces.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.5</strong> Illegal moves:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.5.1</strong> An illegal move is completed once the player
          has pressed his/her clock. If during a game it is found that an
          illegal move has been completed, the position immediately before the
          irregularity shall be reinstated. If the position immediately before
          the irregularity cannot be determined, the game shall continue from
          the last identifiable position prior to the irregularity. Articles 4.3
          and 4.7 apply to the move replacing the illegal move. The game shall
          then continue from this reinstated position.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.5.2</strong> If the player has moved a pawn to the furthest
          distant rank, pressed the clock, but not replaced the pawn with a new
          piece, the move is illegal. The pawn shall be replaced by a queen of
          the same colour as the pawn.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.5.3</strong> If the player presses the clock without making
          a move, it shall be considered and penalised as if an illegal move.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.5.4</strong> If a player uses two hands to make a single
          move (for example in case of castling, capturing or promotion) and
          pressed the clock, it shall be considered and penalised as if an
          illegal move.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>7.5.5</strong> After the action taken under Article 7.5.1,
          7.5.2, 7.5.3 or 7.5.4 for the first completed illegal move by a
          player, the arbiter shall give two minutes extra time to his/her
          opponent; for the second completed illegal move by the same player the
          arbiter shall declare the game lost by this player. However, the game
          is drawn if the position is such that the opponent cannot checkmate
          the player’s king by any possible series of legal moves.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>7.6</strong> Displaced pieces:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          If, during a game it is found that any piece has been displaced from
          its correct square, the position before the irregularity shall be
          reinstated. If the position immediately before the irregularity cannot
          be determined, the game shall continue from the last identifiable
          position prior to the irregularity. The game shall then continue from
          this reinstated position.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Article 8: The recording of the moves
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8.1</strong> How the moves shall be recorded:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.1.1</strong> In the course of play each player is required
          to record his/her own moves and those of his/her opponent in the
          correct manner, move after move, as clearly and legibly as possible,
          in one of the following ways:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>8.1.1.1</strong> by writing in the algebraic notation
          (Appendix C), on the paper ‘scoresheet’ prescribed for the
          competition.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>8.1.1.2</strong> by entering moves on the FIDE certified
          ‘electronic scoresheet’ prescribed for the competition.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.1.2</strong> It is forbidden to record the moves in advance,
          unless the player is claiming a draw according to Article 9.2, or 9.3
          or adjourning a game according to Guidelines I.1.1
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.1.3</strong> A player may reply to his/her opponent’s move
          before recording it, if he/she so wishes. He/She must record his/her
          previous move before making another.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.1.4</strong> The scoresheet shall be used only for recording
          the moves, the times of the clocks, offers of a draw, matters relating
          to a claim and other relevant data.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.1.5</strong> Both players must record the offer of a draw on
          the scoresheet with a symbol (=).
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.1.6</strong> If a player is unable to keep score, an
          assistant, who must be acceptable to the arbiter, may be provided by
          the player to record the moves. His/Her clock shall be adjusted by the
          arbiter in an equitable way. This adjustment of the clock shall not
          apply to a player with a disability.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8.2</strong> The scoresheet shall be visible to the arbiter
          throughout the game.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8.3</strong> The scoresheets are the property of the organiser
          of the competition. An electronic scoresheet with an evident defect
          shall be replaced by the arbiter.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8.4</strong> If a player has less than five minutes left on
          his/her clock during an allotted period of time and does not have
          additional time of 30 seconds or more added with each move, then for
          the remainder of the period he/she is not obliged to meet the
          requirements of Article 8.1.1.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8.5</strong> Incomplete scoresheets:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.5.1</strong> If neither player keeps score under Article
          8.4, the arbiter or an assistant should try to be present and keep
          score. In this case, immediately after a flag has fallen the arbiter
          shall pause the chessclock. Then both players shall update their
          scoresheets, using the arbiter’s or the opponent’s scoresheet.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.5.2</strong> If only one player has not kept score under
          Article 8.4, he/she must, as soon as either flag has fallen, update
          his/her scoresheet completely before moving a piece on the chessboard.
          Provided it is that player’s move, he/she may use his/her opponent’s
          scoresheet, but must return it before making a move.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>8.5.3</strong> If no complete scoresheet is available, the
          players must reconstruct the game on a second chessboard under the
          control of the arbiter or an assistant. He/She shall first record the
          actual game position, clock times, whose clock was running and the
          number of moves made/completed, if this information is available,
          before reconstruction takes place.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8.6</strong> If the scoresheets cannot be brought up to date
          showing that a player has overstepped the allotted time, the next move
          made shall be considered as the first of the following time period,
          unless there is evidence that more moves have been made or completed.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>8.7</strong> At the conclusion of the game both players shall
          indicate the result of the game by signing both scoresheets or approve
          the result on their electronic scoresheets. Even if incorrect, this
          result shall stand, unless the arbiter decides otherwise.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Article 9: The Drawn Game
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>9.1</strong> Draw offers and event Regulations:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.1.1</strong> The regulations of an event may specify that
          players cannot offer or agree to a draw, whether in less than a
          specified number of moves or at all, without the consent of the
          arbiter.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.1.2</strong> However, if the regulations of an event allow a
          draw agreement the following shall apply:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>9.1.2.1</strong> A player wishing to offer a draw shall do so
          after having made a move on the chessboard and before pressing his/her
          clock. An offer at any other time during play is still valid but
          Article 11.5 must be considered. No conditions can be attached to the
          offer. In both cases the offer cannot be withdrawn and remains valid
          until the opponent accepts it, rejects it orally, rejects it by
          touching a piece with the intention of moving or capturing it, or the
          game is concluded in some other way.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>9.1.2.2</strong> The offer of a draw shall be recorded by each
          player on his/her scoresheet with the symbol (=).
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>9.1.2.3</strong> A claim of a draw under Article 9.2 or 9.3
          shall be considered to be an offer of a draw.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>9.2</strong> The game is drawn, upon a correct claim by a
          player having the move, when the same position for at least the third
          time (not necessarily by a repetition of moves):
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.2.1</strong> is about to appear, if he/she first indicates
          his/her move, which cannot be changed, by writing it on the paper
          scoresheet or entering it on the electronic scoresheet and declares to
          the arbiter his/her intention to make this move, or
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.2.2</strong> has just appeared, and the player claiming the
          draw has the move.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.2.3</strong> Positions are considered the same if and only
          if the same player has the move, pieces of the same kind and colour
          occupy the same squares and the possible moves of all the pieces of
          both players are the same. Thus positions are not the same if:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>9.2.3.1</strong> at the start of the sequence a pawn could
          have been captured en passant.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>9.2.3.2</strong> a king had castling rights with a rook that
          has not been moved, but forfeited these after moving. The castling
          rights are lost only after the king or rook is moved.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>9.3</strong> The game is drawn, upon a correct claim by a
          player having the move, if:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.3.1</strong> he/she indicates his/her move, which cannot be
          changed, by writing it on the paper scoresheet or entering it on the
          electronic scoresheet and declares to the arbiter his/her intention to
          make this move which will result in the last 50 moves by each player
          having been made without the movement of any pawn and without any
          capture, or
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.3.2</strong> the last 50 moves by each player have been
          completed without the movement of any pawn and without any capture.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>9.4</strong> If the player touches a piece as in Article 4.3,
          he/she loses the right to claim a draw under Article 9.2 or 9.3 on
          that move.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>9.5</strong> Draw claims:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.5.1</strong> If a player claims a draw under Article 9.2 or
          9.3, he/she or the arbiter shall pause the chessclock. He/She is not
          allowed to withdraw his/her claim.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.5.2</strong> If the claim is found to be correct, the game
          is immediately drawn.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.5.3</strong> If the claim is found to be incorrect, the
          arbiter shall add two minutes to the opponent’s remaining thinking
          time. Then the game shall continue. If the claim was based on an
          intended move, this move must be made in accordance with Articles 3
          and 4.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>9.6</strong> If one or both of the following occur(s) then the
          game is drawn:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.6.1</strong> the same position has appeared, as in 9.2.2 at
          least five times.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>9.6.2</strong> any series of at least 75 moves have been made
          by each player without the movement of any pawn and without any
          capture. If the last move resulted in checkmate, that shall take
          precedence.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Article 10: Points
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>10.1</strong> Unless the regulations of an event specify
          otherwise, a player who wins his/her game, or wins by forfeit, scores
          one point (1), a player who loses his/her game, or forfeits, scores no
          points (0), and a player who draws his/her game scores a half point
          (½).
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>10.2</strong> The total score of any game can never exceed the
          maximum score normally given for that game. Scores given to an
          individual player must be those normally associated with the game, for
          example a score of ¾ - ¼ is not allowed.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Article 11: The Conduct of the Players
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.1</strong> The players shall take no action that will bring
          the game of chess into disrepute.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.2</strong> Playing venue and playing area:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>11.2.1</strong> The ‘playing venue’ is defined as the ‘playing
          area’, rest rooms, toilets, refreshment area, area set aside for
          smoking and other places as designated by the arbiter.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>11.2.2</strong> The playing area is defined as the place where
          the games of a competition are played.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>11.2.3</strong> Only with the permission of the arbiter can:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>11.2.3.1</strong> a player leave the playing venue,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>11.2.3.2</strong> the player having the move be allowed to
          leave the playing area.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>11.2.3.3</strong> a person who is neither a player nor arbiter
          be allowed access to the playing area.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>11.2.4</strong> The regulations of an event may specify that
          the opponent of the player having a move must report to the arbiter
          when he/she wishes to leave the playing area.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.3</strong> Notes and electronic devices:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>11.3.1</strong> During play the players are forbidden to use
          any notes, sources of information or advice, or analyse any game on
          another chessboard.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>11.3.2</strong> During a game, a player is forbidden to have
          any electronic device not specifically approved by the arbiter in the
          playing venue.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>11.3.2.1</strong> However, the regulations of an event may
          allow such devices to be stored in a player’s bag, provided the device
          is completely switched off. This bag must be placed as agreed with the
          arbiter. Both players are forbidden to use this bag without permission
          of the arbiter.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>11.3.2.2</strong> If it is evident that a player has such a
          device on their person in the playing venue, the player shall lose the
          game. The opponent shall win. The regulations of an event may specify
          a different, less severe, penalty.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>11.3.3</strong> The arbiter may require the player to allow
          his/her clothes, bags, other items or body to be inspected, in
          private. The arbiter or person authorised by the arbiter shall inspect
          the player, and shall be of the same gender as the player. If a player
          refuses to cooperate with these obligations, the arbiter shall take
          measures in accordance with Article 12.9.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>11.3.4</strong> Smoking, including e-cigarettes, is permitted
          only in the section of the venue designated by the arbiter.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.4</strong> Players who have finished their games shall be
          considered to be spectators.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.5</strong> It is forbidden to distract or annoy the
          opponent in any manner whatsoever. This includes unreasonable claims,
          unreasonable offers of a draw or the introduction of a source of noise
          into the playing area.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.6</strong> Infraction of any part of Articles 11.1 – 11.5
          shall lead to penalties in accordance with Article 12.9.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.7</strong> Persistent refusal by a player to comply with
          the Laws of Chess shall be penalised by loss of the game. The arbiter
          shall decide the score of the opponent.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.8</strong> If both players are found guilty according to
          Article 11.7, the game shall be declared lost by both players.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.9</strong> A player shall have the right to request from
          the arbiter an explanation of particular points in the Laws of Chess.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.10</strong> Unless the regulations of an event specify
          otherwise, a player may appeal against any decision of the arbiter,
          even if the player has signed the scoresheet (see Article 8.7).
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.11</strong> Both players must assist the arbiter in any
          situation requiring reconstruction of the game, including draw claims.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>11.12</strong> Checking a ‘three times occurrence of the
          position’ or a ’50 moves’ claim is a duty of the players, under
          supervision of the arbiter.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Article 12: The Role of the Arbiter (see Preface)
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.1</strong> The arbiter shall see that the Laws of Chess are
          observed.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.2</strong> The arbiter shall:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.2.1</strong> ensure fair play,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.2.2</strong> act in the best interest of the competition,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.2.3</strong> ensure that a good playing environment is
          maintained,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.2.4</strong> ensure that the players are not disturbed,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.2.5</strong> supervise the progress of the competition,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.2.6</strong> take special measures in the interests of
          disabled players and those who need medical attention,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.2.7</strong> follow the Fair-Play Rules or Guidelines
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.3</strong> The arbiter shall observe the games, especially
          when the players are short of time, enforce decisions he/she has made,
          and impose penalties on players where appropriate.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.4</strong> The arbiter may appoint assistants to observe
          games, for example when several players are short of time.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.5</strong> The arbiter may award either or both players
          additional time in the event of external disturbance of the game.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.6</strong> The arbiter must not intervene in a game except
          in cases described by the Laws of Chess. He/She shall not indicate the
          number of moves completed, except in applying Article 8.5 when at
          least one flag has fallen. The arbiter shall refrain from informing a
          player that his/her opponent has completed a move or that the player
          has not pressed his/her clock.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.7</strong> If someone observes an irregularity, he/she may
          inform only the arbiter. Players in other games must not to speak
          about or otherwise interfere in a game. Spectators are not allowed to
          interfere in a game. The arbiter may expel offenders from the playing
          venue.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.8</strong> Unless authorised by the arbiter, it is
          forbidden for anybody to use a mobile phone or any kind of
          communication device in the playing venue or any contiguous area
          designated by the arbiter.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>12.9</strong> Options available to the arbiter concerning
          penalties:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.1</strong> warning,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.2</strong> increasing the remaining time of the opponent,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.3</strong> reducing the remaining time of the offending
          player,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.4</strong> increasing the points scored in the game by
          the opponent to the maximum available for that game,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.5</strong> reducing the points scored in the game by the
          offending person,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.6</strong> declaring the game to be lost by the offending
          player (the arbiter shall also decide the opponent’s score),
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.7</strong> a fine announced in advance,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.8</strong> exclusion from one or more rounds,
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>12.9.9</strong> expulsion from the competition.
        </Typography>
      </Box>
    </Box>
  );
}

export default CompetitiveRulesOfPlay;
