import React, { useState, useRef, useEffect } from "react";
import {
  Box,
  Button,
  Paper,
  TextField,
  useTheme,
  Typography,
} from "@mui/material";
import { tokens } from "../../../styles/theme";
import ContentHeader from "../../../components/ContentHeader";
import HelpModal from "../../../components/HelpModal";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import HangmanHelp from "../../../help/HangmanHelp";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HighlightOffRoundedIcon from "@mui/icons-material/HighlightOffRounded";
import VisibilityRoundedIcon from "@mui/icons-material/VisibilityRounded";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { Helmet } from "react-helmet";
import { chessterms } from "../../../data/chessterms/chessterms";
import { trackEvent } from "../../../config/ga";
import {
  incrementGameProperty,
  updateUserData,
} from "../../../features/Firestore";
import { useUser } from "../../../context/UserContext";
import { useShare } from "../../../context/ShareContext";

function Hangman() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const [toPlay, setToPlay] = useState("");
  const [selectedPhrase, setSelectedPhrase] = useState();
  const [selectedPhraseArray, setSelectedPhraseArray] = useState([]);
  const [userLetters, setUserLetters] = useState([]);
  const [letter, setLetter] = useState("");
  const [isCompleted, setIsCompleted] = useState(false);
  const [isCorrect, setIsCorrect] = useState(null); // State to store if the full sentence guess is correct or incorrect
  const letterGuessRef = useRef(null);
  const [firstGame, setFirstGame] = useState(false);
  const [showHint, setShowHint] = useState(false);
  const [hint, setHint] = useState("");
  const [errorCount, setErrorCount] = useState(0);
  const [showGiveUp, setShowGiveUp] = useState(false);
  const [gameStarted, setGameStarted] = useState(false); // New state for gameStarted
  const { userData, userRef } = useUser();
  const { setShareData } = useShare();

  function getRandomNumber(n) {
    return Math.floor(Math.random() * n);
  }

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/hangman",
      title: "Chessboard Magic - Chess Hangman",
      description:
        "Combine the fun of hangman with chess! Guess famous Chess players, terms, engines or openings. Challenge your chess knowledge!",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const handleNewGame = async () => {
    const filteredChessterms = chessterms.filter((term) => term.Length >= 8);
    let randomNumber = getRandomNumber(filteredChessterms.length);
    let newSelectedPhrase = filteredChessterms[randomNumber];
    setSelectedPhrase(newSelectedPhrase);

    if (newSelectedPhrase.Type === "T") {
      setHint("Chess Term");
    } else if (
      newSelectedPhrase.Type === "P" ||
      newSelectedPhrase.Type === "W"
    ) {
      setHint("Chess Player");
    } else if (newSelectedPhrase.Type === "E") {
      setHint("Chess Engine");
    } else if (newSelectedPhrase.Type === "O") {
      setHint("Chess Opening");
    }
    setSelectedPhraseArray(newSelectedPhrase.Term.toUpperCase().split(""));
    setToPlay(toPlay);
    setIsCompleted(false);
    setShowHint(false);
    setFirstGame(true);
    setShowGiveUp(false);
    setGameStarted(true);
    setUserLetters([]);
    setErrorCount(0);
    setIsCorrect(false);

    //GA Tracking
    trackEvent("Games", "Hangman-Play", "Hangman");
    // Internal Tracking
    incrementGameProperty("Hangman-Play");
    if (userData) {
      if (!userData.Puzzles) {
        userData.Puzzles = {};
      }
      if (userData.Puzzles.Hangman) {
        userData.Puzzles.Hangman.Played =
          (userData.Puzzles.Hangman.Played || 0) + 1;
      } else {
        userData.Puzzles.Hangman = {
          Played: 1,
          Completed: 0,
        };
      }
      updateUserData(userRef, userData);
    }
  };

  const handleAddLetter = () => {
    if (letter && !userLetters.includes(letter)) {
      // Handles the error count.
      if (!selectedPhraseArray.includes(letter.toUpperCase())) {
        setErrorCount((prevCount) => {
          const newCount = prevCount + 1;
          if (newCount === 5) {
            setIsCompleted(true);
            setIsCorrect(false);

            // GA Tracking
            trackEvent("Games", "Hangman-Failed", "Hangman");
            // Internal Tracking
            incrementGameProperty("Hangman-Failed");
            if (userData) {
              userData.Puzzles.Hangman.Failed =
                (userData.Puzzles.Hangman.Failed || 0) + 1;
              updateUserData(userRef, userData);
            }
          }
          return newCount;
        });
      }

      // Adds the letters
      setUserLetters((prevUserLetters) => {
        const newUserLetters = [...prevUserLetters, letter.toUpperCase()];

        if (checkIfCompleted(newUserLetters)) {
          setIsCompleted(true);
          setIsCorrect(true);

          // GA Tracking
          trackEvent("Games", "Hangman-Success", "Hangman");
          // Internal Tracking
          incrementGameProperty("Hangman-Completed");
          if (userData) {
            userData.Puzzles.Hangman.Completed =
              (userData.Puzzles.Hangman.Completed || 0) + 1;
            updateUserData(userRef, userData);
          }
        }

        return newUserLetters;
      });

      setLetter("");
      if (letterGuessRef.current) {
        letterGuessRef.current.focus(); // Set focus on the TextField
      }
    }
  };

  const checkIfCompleted = (userLetters) => {
    // Clean the selectedPhraseArray
    const cleanedSelectedPhraseArray = selectedPhraseArray
      .map((char) => char.toUpperCase().replace(/[^A-Z0-9]/g, ""))
      .join("")
      .split("");

    // Check if every character in cleanedSelectedPhraseArray is included in userLetters
    return cleanedSelectedPhraseArray.every((char) =>
      userLetters.includes(char)
    );
  };

  const [open, setOpen] = useState(false);

  const handleShowHint = () => {
    setShowHint(true);
    setShowGiveUp(true);
  };

  const handleGiveUp = () => {
    setIsCompleted(true);
    setIsCorrect(false);
    trackEvent("Games", "Game-Failed", "Hangman");
    if (userData) {
      incrementGameProperty("Hangman-Failed");
      userData.Puzzles.Hangman.Failed =
        (userData.Puzzles.Hangman.Failed || 0) + 1;
      updateUserData(userRef, userData);
    }
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Box>
      <ContentHeader
        title="Chess Hangman"
        subtitle="Combine the fun of hangman with chess! Guess famous Chess players, terms, engines or openings. Challenge your chess knowledge!"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[1]}
      />
      <Helmet>
        <title>Chess Hangman</title>
        <meta
          name="description"
          content="Combine the fun of hangman with chess! Guess famous Chess players, terms, engines or openings. Challenge your chess knowledge!"
        />
        <meta property="og:title" content="Chess Hangman" />
        <meta
          property="og:description"
          content="Combine the fun of hangman with chess! Guess famous Chess players, terms, engines or openings. Challenge your chess knowledge!"
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/games/hangman.png`}
        />
        <meta property="og:url" content={`${process.env.PUBLIC_URL}/hangman`} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Hangman" />
        <meta
          name="twitter:description"
          content="Combine the fun of hangman with chess! Guess famous Chess players, terms, engines or openings. Challenge your chess knowledge!"
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/games/hangman.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleNewGame}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.black[900] }}
            />
          }
        >
          Play
        </Button>
        <Button
          variant="contained"
          onClick={handleClickOpen}
          style={{ marginRight: 10 }}
          startIcon={<HelpOutlineIcon style={{ color: colors.black[900] }} />}
        >
          Help
        </Button>
        {!showGiveUp && (
          <Button
            variant="contained"
            onClick={handleShowHint}
            startIcon={
              <VisibilityRoundedIcon style={{ color: colors.black[900] }} />
            }
            disabled={!gameStarted} // Disable button when game is not started
          >
            Hint
          </Button>
        )}
        {showGiveUp && (
          <Button
            variant="contained"
            onClick={handleGiveUp}
            startIcon={
              <HighlightOffRoundedIcon style={{ color: colors.red[500] }} />
            }
            disabled={!gameStarted} // Disable button when game is not started
          >
            Quit
          </Button>
        )}

        <HelpModal
          open={open}
          onClose={handleClose}
          title="Chess Hangman Help"
          content={<HangmanHelp />}
        ></HelpModal>
      </Box>
      {showHint && (
        <p sx={{ Margin: 0 }}>
          <i>Hint: {hint}</i>
        </p>
      )}
      {firstGame && (
        <Box
          style={{
            display: "flex",
            alignItems: "center",
            margin: "10px 0px 10px 0px",
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/img/games/hangman${errorCount}.png`}
            alt={`Hangman stage ${errorCount}`}
            style={{ width: "120px", marginRight: "10px" }}
          />
          <p>
            <b>{errorCount}</b> Failed Guesses
            <br /> out of 5
          </p>
        </Box>
      )}

      <Box
        sx={{
          p: "0px 0px 0px 0px",
          display: "flex",
          flexDirection: {
            xs: "column", // All screens smaller than 'sm'
            sm: "column", // All screens smaller than 'md'
            md: "row", // Medium screens and larger
          },
          alignItems: "flex-start", // Align items at the start of the flex container
          width: "100%", // Use the full width of the container
        }}
      >
        {firstGame && (
          <div
            id="hangman"
            style={{
              width: "100%", // Full width in column layout
              padding: "0px 10px 00px 0px", // Uniform padding
              boxSizing: "border-box", // Include padding and border in the element's total width and height
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row", // Change flexDirection to "row"
                gap: 2,
                mt: 2,
                flexWrap: "wrap", // Allow flex items to wrap to new row
              }}
            >
              {selectedPhraseArray.length > 0 &&
                selectedPhraseArray.map((word, wordIndex) => (
                  <Box
                    key={wordIndex}
                    sx={{ display: "flex", gap: 1, minHeight: 27 }}
                  >
                    {word.split("").map((char, charIndex) => (
                      <React.Fragment key={charIndex}>
                        {char === " " ? (
                          <span
                            style={{ width: "35px" }} // Set width to match Paper component
                          >
                            &nbsp;
                          </span>
                        ) : (
                          <Paper
                            elevation={3}
                            sx={{
                              textAlign: "center",
                              minWidth: 35,
                              minHeight: 35,
                              height: 35,
                              lineHeight: "35px", // Ensures the text is vertically centered
                            }}
                          >
                            {
                              /[A-Za-z]/.test(char) // Check if the character is a letter
                                ? userLetters.includes(char.toUpperCase())
                                  ? char.toUpperCase()
                                  : ""
                                : char // Render the character as it is if it's not a letter
                            }
                          </Paper>
                        )}
                      </React.Fragment>
                    ))}
                  </Box>
                ))}
            </Box>

            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 1,
                mt: 5,
                alignItems: "center",
                height: "auto", // Change this to auto to accommodate wrapping
                width: "100%",
              }}
            >
              {userLetters.map((letter, index) => (
                <Typography sx={{ width: 20, textAlign: "center" }} key={index}>
                  {letter}
                </Typography>
              ))}
              {!isCompleted && (
                <TextField
                  id="letterGuess"
                  variant="outlined"
                  inputRef={letterGuessRef}
                  value={letter}
                  sx={{
                    width: 35,
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.23)", // Default border color
                      },
                      "&:hover fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.23)", // Hover border color
                      },
                      "&.Mui-focused fieldset": {
                        borderColor: "rgba(0, 0, 0, 0.23)", // Focused border color
                      },
                    },
                  }}
                  onChange={(e) => {
                    const value = e.target.value.toUpperCase();
                    if (value.length <= 1 && /^[A-Z]*$/.test(value)) {
                      setLetter(value);
                    }
                  }}
                  inputProps={{
                    maxLength: 1,
                    style: { textAlign: "center", padding: "6px" }, // Center the text and adjust padding
                  }}
                  autoComplete="off"
                />
              )}
              <Button
                variant="contained"
                onClick={handleAddLetter}
                disabled={isCompleted}
                sx={{ textDecoration: "none" }}
              >
                Guess
              </Button>
            </Box>

            {isCompleted && (
              <>
                <p>
                  {isCorrect ? (
                    <CheckCircleOutlineIcon
                      style={{
                        color: "green",
                        marginRight: "10px",
                        marginBottom: "-10px",
                        verticalAlign: "middleftle",
                        fontSize: "32px",
                      }}
                    />
                  ) : !isCorrect ? (
                    <ErrorOutlineIcon
                      style={{
                        color: "red",
                        marginRight: "10px",
                        marginBottom: "-10px",
                        verticalAlign: "left",
                        fontSize: "32px",
                      }}
                    />
                  ) : null}
                  {isCorrect ? "Correct" : "Incorrect"}
                </p>
                <Typography variant="h6">{selectedPhrase?.Term}</Typography>
                <Typography sx={{ paddingBottom: "400px", maxWidth: "600px" }}>
                  {selectedPhrase?.Description}
                </Typography>
              </>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
}

export default Hangman;
