import React, { useState, useEffect } from "react";
import {
  Box,
  useTheme,
  TextField,
  Grid,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  ButtonGroup,
  Avatar,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import { chessresources } from "../../../data/chessresources/chessresources";
import WebsiteIcon from "../../../icons/WebsiteIcon";
import SoftwareIcon from "../../../icons/SoftwareIcon";
import YouTubeIcon from "../../../icons/YouTubeIcon";
import ExpandableTypography from "../../../components/ExpandableTypograph";
import { useShare } from "../../../context/ShareContext";

function ChessResources() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [searchTerm, setSearchTerm] = useState("");
  const [filterType, setFilterType] = useState("All");
  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/chessresources",
      title: "Chessboard Magic - Chess Resources",
      description:
        "Discover top online chess resources for all levels: tutorials, strategy guides, puzzles, and interactive platforms.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const handleFilterChange = (type) => {
    setFilterType(type);
  };

  // Add "YouTube" to resourceTypes and remove "YouTube Channel" and "YouTube Playlist"
  const resourceTypes = [
    "All",
    ...new Set(
      chessresources.map((res) =>
        res.Type === "YouTube Channel" || res.Type === "YouTube Playlist"
          ? "YouTube"
          : res.Type
      )
    ),
  ];

  const filteredResources = chessresources.filter((resource) => {
    const matchesSearchTerm =
      resource.Title.toLowerCase().includes(searchTerm.toLowerCase()) ||
      resource.Description.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesFilterType =
      filterType === "All" ||
      (filterType === "YouTube" &&
        (resource.Type === "YouTube Channel" ||
          resource.Type === "YouTube Playlist")) ||
      resource.Type === filterType;
    return matchesSearchTerm && matchesFilterType;
  });

  const sortedResources = filteredResources.sort((a, b) =>
    a.Title.localeCompare(b.Title)
  );

  return (
    <Box>
      <ContentHeader
        title="Chess Resources"
        subtitle="Discover top online chess resources for all levels: tutorials, strategy guides, puzzles, and interactive platforms."
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[10]}
      />
      <Helmet>
        <title>Chess Resources</title>
        <meta
          name="description"
          content="Discover top online chess resources for all levels: tutorials, strategy guides, puzzles, and interactive platforms."
        />
        <meta property="og:title" content="Chess Resources" />
        <meta
          property="og:description"
          content="Discover top online chess resources for all levels: tutorials, strategy guides, puzzles, and interactive platforms."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/chessresources.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/chessresources`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Resources" />
        <meta
          name="twitter:description"
          content="Discover top online chess resources for all levels: tutorials, strategy guides, puzzles, and interactive platforms."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/chessresources.png`}
        />
      </Helmet>

      <Box>
        <TextField
          label="Search"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={handleSearchChange}
          sx={{ mb: 2 }}
          focused={false}
        />
        <ButtonGroup
          variant="contained"
          aria-label="outlined primary button group"
          sx={{ mb: 2 }}
        >
          {resourceTypes.map((type) => (
            <Button key={type} onClick={() => handleFilterChange(type)}>
              {type}
            </Button>
          ))}
        </ButtonGroup>
      </Box>

      <Grid container spacing={2} sx={{ mb: 2 }}>
        {sortedResources.map((resource, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card>
              <CardContent>
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    {resource.Type === "Website" ? (
                      <WebsiteIcon fontSize="large" />
                    ) : resource.Type === "Software" ? (
                      <SoftwareIcon fontSize="large" />
                    ) : resource.Type === "YouTube Channel" ||
                      resource.Type === "YouTube Playlist" ? (
                      <YouTubeIcon fontSize="large" />
                    ) : (
                      <Avatar>{resource.Title.charAt(0)}</Avatar>
                    )}
                  </Grid>
                  <Grid item xs>
                    <Typography sx={{ fontWeight: "bold" }}>
                      {resource.Title}
                    </Typography>
                    <Typography color="text.secondary">
                      {resource.Type === "YouTube Channel" ||
                      resource.Type === "YouTube Playlist"
                        ? "YouTube"
                        : resource.Type}
                    </Typography>
                  </Grid>
                </Grid>
                <ExpandableTypography
                  text={resource.Description}
                  buttonPosition="right"
                  charLimit="100"
                />
              </CardContent>
              <CardActions>
                <Button variant="contained" href={resource.URL} target="_blank">
                  Visit
                </Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default ChessResources;
