import React from "react";
import { SvgIcon } from "@mui/material";

function GuessWhoIcon(props) {
  return (
    <SvgIcon {...props} viewBox="0 0 1080 1080">
      <g transform="scale(0.9) translate(0, 0)">
        <path d="m328.8 612-6-38.398-56.398-12-129.6 246 154.8-63.602 31.199 57.602-72 70.801 280.8 118.8 25.199-98.398c-165.6-55.203-228-280.8-228-280.8z" />
        <path d="m583.2 631.2h12l48 80.398s72-8.3984 92.398-20.398c15.602-9.6016 45.602-55.199 45.602-55.199l1.1992-34.801h-406.8l1.1992 34.801s30 45.602 45.602 55.199c20.398 12 92.398 20.398 92.398 20.398l48-80.398z" />
        <path d="m882 733.2 181.2 74.398-159.6-241.2-70.801 9.6016s-8.3984 112.8-58.801 158.4c0 0-199.2 116.4-199.2 375.6 0 0 175.2-261.6 362.4-256.8l-96-63.602zm-8.3984-58.801 48 18-31.199-105.6 114 176.4-123.6-57.602-40.801 48zm10.797 169.2c-158.4 9.6016-291.6 213.6-291.6 213.6 56.402-168 223.2-231.6 223.2-231.6l-25.199-49.199z" />
        <path d="m818.4 381.6s-99.602 37.199-214.8 33.602h-3.6016-3.6016c-115.2 2.3984-214.8-33.602-214.8-33.602-31.195 0-303.59 98.398-303.59 98.398 43.199 64.801 478.8 74.398 522 73.199 42 1.1992 478.8-8.3984 522-73.199 0 0-272.4-98.398-303.6-98.398zm-225.6 146.4s303.6-32.398 324-61.199l-85.199-60 206.4 74.398s-238.8 48-445.2 46.801z" />
        <path d="m734.4 99.602c-33.598-25.203-116.4 3.5977-134.4 10.797-18-7.1992-100.8-34.801-134.4-10.801-38.398 27.602-64.801 196.8-64.801 196.8 57.602 8.3984 166.8 14.398 195.6 15.602h3.6016 3.6016c28.801-1.1992 138-7.1992 195.6-15.602-0.007812 0-26.406-168-64.809-196.8zm-134.4 188.4 114-46.801s13.199-116.4-99.602-112.8c0 0 78-26.398 99.602-4.8008 21.602 21.602 52.801 140.4 52.801 140.4z" />{" "}
      </g>
    </SvgIcon>
  );
}

export default GuessWhoIcon;
