import React, { useEffect } from "react";
import {
  Box,
  Typography,
  useTheme,
  Card,
  CardContent,
  CardMedia,
  CardHeader,
  useMediaQuery,
} from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import ExtensionRoundedIcon from "@mui/icons-material/ExtensionRounded";
import MyCard from "../../../components/MyCard";
import HandymanIcon from "@mui/icons-material/Handyman";
import LocalLibraryRoundedIcon from "@mui/icons-material/LocalLibraryRounded";
import ChevronLeftRoundedIcon from "@mui/icons-material/ChevronLeftRounded";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import StarIcon from "@mui/icons-material/Star";
import { highlights } from "../../../data/highlights/highlights";
import { useShare } from "../../../context/ShareContext";

// Shuffle function to randomize the array
const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const Homepage = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setShareData } = useShare();

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    const newShareData = {
      url: `https://chessboardmagic.com`,
      title: "Chessboard Magic",
      description:
        "Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  const gameItems = [
    {
      title: "Play the Opening",
      description:
        "Test your knowledge by correctly playing named chess openings, and deepen your familiarity with a variety of openings",
      img: "./img/games/playtheopening.png",
      link: "playtheopening",
    },
    {
      title: "Guess the Opening",
      description:
        "A Wordle-like chess game where you challenge yourself to figure out the opening move sequence",
      img: "./img/games/guesstheopening.png",
      link: "guesstheopening",
    },
    {
      title: "Guess the Elo",
      description:
        "Estimate players' ratings based on their game moves in this challenging and fun game, and see how accurately you can guess their Elo",
      img: "./img/games/guesstheelo.png",
      link: "guesstheelo",
    },
    {
      title: "Guess The Eval",
      description:
        "Test your accuracy in evaluating chess positions in this fun and challenging game, and improve your ability with each guess",
      img: "./img/games/guesstheeval.png",
      link: "guesstheeval",
    },
    {
      title: "Guess The Move",
      description:
        "A position appears—can you outguess the engine's next move? Test your chess instincts and see if you can match its strategy!",
      img: "./img/games/guessthemove.png",
      link: "guessthemove",
    },
    {
      title: "Guess Who",
      description:
        "Put your chess knowledge to the test by matching the correct name to the famous player. How well do you know the masters?",
      img: "./img/games/guesswho.png",
      link: "guesswho",
    },
    {
      title: "Chess Slide",
      description:
        "Enjoy a challenging slider puzzle where you slide tiles to reveal a hidden chess opening, testing your logic and problem-solving skills",
      img: "./img/games/chessslide.png",
      link: "chessslide",
    },
    {
      title: "Chess Anagrams",
      description:
        "Form words from scrambled letters related to chess terms, players, openings, or engines. Fast-paced and fun!",
      img: "./img/games/anagrams.png",
      link: "anagrams",
    },
    {
      title: "Chess Hangman",
      description:
        "Combine the fun of hangman with chess! Guess famous players or chess terms. Challenge your chess knowledge",
      img: "./img/games/hangman.png",
      link: "hangman",
    },
    {
      title: "Chess Wordsearch",
      description:
        "Challenge your mind with Chess Wordsearch! Find hidden chess terms in a grid and enhance your chess vocabulary",
      img: "./img/games/wordsearch.png",
      link: "wordsearch",
    },
    {
      title: "Chess Crossword",
      description:
        "Solve chess-themed crosswords and improve your chess knowledge while having fun!",
      img: "./img/games/crossword.png",
      link: "crossword",
    },
    {
      title: "Chess Image Puzzle",
      description:
        "Engage in jigsaw-like puzzles featuring images of renowned chess players, piecing together each portrait for a challenging experience",
      img: "./img/games/imagePuzzle.png",
      link: "imagepuzzle",
    },
  ];

  const toolItems = [
    {
      title: "Chess Notation Trainer",
      description:
        "Master chess notation by typing moves in this interactive trainer. Practice with random games and improve your skills!",
      img: "./img/tools/notationtrainer.png",
      link: "notationtrainer",
    },
    {
      title: "Gif Generator",
      description:
        "Turn Chess PGNs into visually engaging sequences effortlessly with our GIF Generator.",
      img: "./img/tools/gifgenerator.png",
      link: "gifgenerator",
    },
    {
      title: "Fischer Random Generator",
      description:
        "Generate randomized chess positions and experience the unpredictability and excitement of Fischer Random Chess",
      img: "./img/tools/fischerrandom.png",
      link: "fischerrandom",
    },
    {
      title: "Opening Explorer",
      description:
        "Explore a wide range of chess openings, and deepen your understanding of variations from the very first moves",
      img: "./img/tools/openingexplorer.png",
      link: "openingexplorer",
    },
  ];

  const libraryItems = [
    {
      title: "World Chess Championships",
      description:
        "Explore and play through the World Chess Championship games, featuring matches from the world's top players",
      img: "./img/library/worldchampionships.png",
      link: "worldchampionships",
    },
    {
      title: "Classic Chess Games",
      description:
        "Explore and play through Classic chess games, featuring matches from some of the greatest players in history",
      img: "./img/library/classicgames.png",
      link: "classicgames",
    },
    {
      title: "Miniature Chess Games",
      description:
        "Discover and analyze Miniature Chess Games, showcasing rapid and decisive victories in just a few moves by master players.",
      img: "./img/library/miniaturegames.png",
      link: "miniaturegames",
    },
    {
      title: "Chess Quotes",
      description:
        "Discover Famous Chess Quotes: A Collection of Inspiring Words",
      img: "./img/library/quotes.png",
      link: "quotes",
    },
    {
      title: "Chess Glossary",
      description: "Explore and demystify Chess terminology",
      img: "./img/library/glossary.png",
      link: "glossary",
    },
    {
      title: "Basic Rules of Play",
      description:
        "Basic chess rules cover piece movement, check and checkmate, castling, pawn promotion, and the initial setup of the board.",
      img: "./img/library/basicrulesofplay.png",
      link: "basicrulesofplay",
    },
    {
      title: "Competitive Rules of Play",
      description:
        "Competitive chess rules ensure fair play, covering player conduct, piece movement, clock use, and dispute resolution.",
      img: "./img/library/competitiverulesofplay.png",
      link: "competitiverulesofplay",
    },
  ];

  // Shuffle the items for each carousel
  const shuffledGameItems = shuffleArray([...gameItems]);
  const shuffledToolItems = shuffleArray([...toolItems]);
  const shuffledLibraryItems = shuffleArray([...libraryItems]);

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));

  const getRandomHighlight = () => {
    const now = new Date();
    const startOfYear = new Date(now.getFullYear(), 0, 0);
    const diff = now - startOfYear;
    const oneDay = 1000 * 60 * 60 * 24;
    const dayOfYear = Math.floor(diff / oneDay);
    const index = dayOfYear % highlights.length;

    return highlights[index];
  };

  const highlight = getRandomHighlight();

  return (
    <Box>
      <Helmet>
        <title>Chessboard Magic</title>
        <meta
          name="description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta property="og:title" content="Chessboard Magic" />
        <meta
          property="og:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
        <meta property="og:url" content="https://chessboardmagic.com" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chessboard Magic" />
        <meta
          name="twitter:description"
          content="Chessboard Magic is the ultimate online destination for chess enthusiasts. Discover innovative training tools, engaging puzzles, and interactive games designed to enhance your chess skills."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/chessboardmagic-social.png"
        />
      </Helmet>
      <ContentHeader
        title="Welcome to Chessboard Magic"
        subtitle="A magical place for Chess Puzzles, Tools and more..."
        color={colors.black[900]}
        backgroundImage="./img/header-background.png"
      />

      {/* Highlight Card */}
      <Card
        sx={{
          width: "100%",
          margin: "auto",
          mb: 3,
          background: "#283593",
          boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.7)",
        }}
      >
        <CardHeader
          avatar={<StarIcon sx={{ color: "#EEEEEE", marginRight: 0 }} />}
          title={
            <Link to={highlight.url} style={{ textDecoration: "none" }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
                <Typography variant="h6" sx={{ color: "#EEEEEE" }}>
                  <b>{highlight.title}</b>
                </Typography>
                <Typography
                  variant="body2"
                  sx={{ color: "#EEEEEE", pt: 0.5, pl: 1 }}
                >
                  Try it now
                </Typography>
              </Box>
            </Link>
          }
          sx={{
            borderBottom: "0.5px solid #000000",
            p: 1,
            "& .MuiCardHeader-avatar": {
              marginRight: 1,
            },
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: isSmallScreen ? "column" : "row",
            gap: "16px",
          }}
        >
          <div style={{ flex: "1" }} sx={{ p: 2, m: 2 }}>
            <Link to={highlight.url}>
              <CardMedia
                component="img"
                image={`${process.env.PUBLIC_URL}/img/${highlight.image}.png`}
                alt={highlight.title}
                style={{ width: "100%", height: "auto" }}
              />
            </Link>
          </div>
          <div
            style={{
              flex: "1",
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <CardContent>
              <Typography color="text.secondary" sx={{ color: "#EEEEEE" }}>
                {highlight.description}
              </Typography>
            </CardContent>
          </div>
        </div>
      </Card>

      {/* Games Section */}
      <Box sx={{ paddingBottom: "10px" }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            paddingBottom: "10px",
            color: colors.black[900],
          }}
        >
          <ExtensionRoundedIcon style={{ color: colors.black[900] }} />
          Games
        </Typography>
        <Box sx={{ paddingBottom: "10px" }}>
          <Carousel
            responsive={responsive}
            arrows
            infinite
            draggable={true}
            itemClass="carousel-item-padding-40-px"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {shuffledGameItems.map((item, index) => (
              <MyCard
                key={index}
                title={item.title}
                description={item.description}
                img={item.img}
                link={item.link}
              />
            ))}
          </Carousel>
        </Box>
      </Box>

      {/* Tools Section */}
      <Box sx={{ paddingBottom: "10px" }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            paddingBottom: "10px",
            color: colors.black[900],
          }}
        >
          <HandymanIcon style={{ color: colors.black[900] }} />
          Tools
        </Typography>
        <Box>
          <Carousel
            responsive={responsive}
            arrows
            infinite
            draggable={true}
            itemClass="carousel-item-padding-40-px"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {shuffledToolItems.map((item, index) => (
              <MyCard
                key={index}
                title={item.title}
                description={item.description}
                img={item.img}
                link={item.link}
              />
            ))}
          </Carousel>
        </Box>
      </Box>

      {/* Library Section */}
      <Box sx={{ paddingBottom: "20px" }}>
        <Typography
          variant="h6"
          sx={{
            display: "flex",
            alignItems: "center",
            gap: 1,
            paddingBottom: "20px",
            color: colors.black[900],
          }}
        >
          <LocalLibraryRoundedIcon style={{ color: colors.black[900] }} />
          Library
        </Typography>
        <Box>
          <Carousel
            responsive={responsive}
            arrows
            infinite
            draggable={true}
            itemClass="carousel-item-padding-40-px"
            customLeftArrow={<CustomLeftArrow />}
            customRightArrow={<CustomRightArrow />}
          >
            {shuffledLibraryItems.map((item, index) => (
              <MyCard
                key={index}
                title={item.title}
                description={item.description}
                img={item.img}
                link={item.link}
              />
            ))}
          </Carousel>
        </Box>
      </Box>
    </Box>
  );
};

// Custom arrow components for the Carousel
const CustomLeftArrow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        left: 0,
        zIndex: 1,
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "#EEEEEE",
        pt: 1,
      }}
    >
      <ChevronLeftRoundedIcon />
    </Box>
  );
};

const CustomRightArrow = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        position: "absolute",
        right: 0,
        zIndex: 1,
        cursor: "pointer",
        backgroundColor: "rgba(0,0,0,0.5)",
        color: "#EEEEEE",
        pt: 1,
      }}
    >
      <ChevronRightRoundedIcon />
    </Box>
  );
};

export default Homepage;
