export const highlights = [
  {
    title: "User Scout",
    image: "analytics/userscout",
    description:
      "Uncover deep insights on chess players with User Scout. Analyze performance trends, track rating progress, and identify strengths and weaknesses. This tool is ideal for players, coaches, and enthusiasts aiming to deepen their understanding of player profiles and competitive dynamics, providing a comprehensive view of a player's journey over time.",
    url: "userscout",
  },
  {
    title: "Opponent Prep",
    image: "analytics/opponentprep",
    description:
      "Prepare for your next chess match by comparing two Lichess profiles. Analyze game stats, study opponent tendencies, and explore opening strategies side by side. Perfect for strategizing and gaining the upper hand before your game.",
    url: "opponentprep",
  },
  {
    title: "Chess Anagrams",
    image: "games/anagrams",
    description:
      "Challenge your chess vocabulary with Chess Anagrams! Unscramble letters to form chess-related terms, from famous players to tactical concepts. This engaging word game tests your knowledge and sharpens your mind, offering a fun way to deepen your understanding of chess terminology while keeping your brain active and entertained.",
    url: "anagrams",
  },
  {
    title: "Chess Slide",
    image: "games/chessslide",
    description:
      "Engage in a classic sliding puzzle with a chess twist. In Chess Slide, your challenge is to rearrange the scrambled pieces of an image featuring a famous chess opening. While it doesn’t involve actual gameplay, this puzzle offers a fun and visual way to interact with chess-related content, testing your patience and puzzle-solving skills.",
    url: "chessslide",
  },
  {
    title: "Chess Crossword",
    image: "games/crossword",
    description:
      "Explore the world of chess through Chess Crossword. Solve crosswords filled with chess terms, famous players, and iconic games. A perfect blend of chess knowledge and classic word puzzles, this game is ideal for learning and reinforcing chess terminology while enjoying the challenge of a traditional crossword format.",
    url: "crossword",
  },
  {
    title: "Guess The Elo",
    image: "games/guesstheelo",
    description:
      "Guess The Elo tests your ability to evaluate chess games. Analyze various positions and guess the player’s Elo rating, sharpening your understanding of different skill levels. Perfect for anyone looking to improve their rating estimation skills, this game enhances your ability to assess the quality of play and predict outcomes more accurately.",
    url: "guesstheelo",
  },
  {
    title: "Guess The Eval",
    image: "games/guesstheeval",
    description:
      "Refine your position evaluation skills with Guess The Eval. Review key chess positions and predict the computer’s evaluation. This game is a fantastic way to deepen your understanding of chess engine analysis, helping you to recognize critical moments and make better decisions in your own games by learning how computers view the board.",
    url: "guesstheeval",
  },
  {
    title: "Guess The Move",
    image: "games/guessthemove",
    description:
      "Test your chess intuition with Guess The Move. Analyze critical chess positions and try to predict the computer’s next move. This game helps sharpen your ability to think ahead and improve your decision-making skills by matching your choices with the engine's recommended move. Challenge yourself to see how well you can anticipate the best moves and enhance your strategic thinking.",
    url: "guessthemove",
  },
  {
    title: "Guess Who",
    image: "games/guesswho",
    description:
      "Test your chess knowledge with Guess Who! Can you identify the famous chess player just by looking at their image? Choose from a list of names and see if you can match the right face to the legend. It's a fun and challenging way to learn more about the masters of chess.",
    url: "guesswho",
  },

  {
    title: "Guess The Opening",
    image: "games/guesstheopening",
    description:
      "Test your chess opening knowledge in this fun, Wordle-inspired challenge. Guess the correct opening by analyzing key positions from the early game within a limited number of tries. Perfect for chess enthusiasts looking to sharpen their opening repertoire while enjoying a strategic puzzle that combines education with entertainment.",
    url: "guesstheopening",
  },
  {
    title: "Chess Hangman",
    image: "games/hangman",
    description:
      "Enjoy a chess-themed twist on the classic Hangman game. Guess chess-related words and terms before time runs out, combining fun with learning. This game offers an engaging way to expand your chess vocabulary, making it both an educational and entertaining activity for chess enthusiasts looking to strengthen their language skills.",
    url: "hangman",
  },
  {
    title: "Image Puzzle",
    image: "games/imagepuzzle",
    description:
      "Solve captivating chess-themed image puzzles. Rearrange scrambled pieces of iconic chess images to recreate the complete picture. This game offers a delightful mix of visual and strategic challenges, perfect for chess lovers who enjoy combining their love of the game with the satisfaction of completing a beautiful and intricate puzzle.",
    url: "imagepuzzle",
  },
  {
    title: "Play The Opening",
    image: "games/playtheopening",
    description:
      "Test your chess opening knowledge with Play The Opening. Navigate through famous chess openings, making the right moves to complete the sequence. This interactive tool is perfect for learning and memorizing key opening strategies, helping you to develop a stronger foundation in the opening phase and prepare more effectively for your games.",
    url: "playtheopening",
  },
  {
    title: "Chess Wordsearch",
    image: "games/wordsearch",
    description:
      "Discover hidden chess terms in a classic wordsearch puzzle. Improve your chess vocabulary while enjoying the thrill of finding words hidden among the letters. This game is a fun and educational activity for chess enthusiasts of all levels, offering a relaxing way to reinforce your knowledge of chess terminology while enjoying a traditional puzzle format.",
    url: "wordsearch",
  },
  {
    title: "Chess Quotes",
    image: "library/quotes",
    description:
      "Explore a collection of inspiring and famous chess quotes. Reflect on the wisdom of legendary players and thinkers, and get motivated for your next chess game. Whether you’re looking for inspiration, insight, or just a few words to ponder, this collection offers something for every chess lover and enthusiast seeking to connect with the rich history of the game.",
    url: "quotes",
  },
  {
    title: "World Championships",
    image: "library/worldchampionships",
    description:
      "Dive into the rich history of World Chess Championships. Explore legendary matches, learn about the champions, and discover the stories behind the most significant events in chess history. This essential resource is perfect for anyone passionate about the pinnacle of chess competition, offering deep insights into the world’s greatest players and their epic battles.",
    url: "worldchampsionships",
  },
  {
    title: "Classic Games",
    image: "library/classicgames",
    description:
      "Relive the most iconic games in chess history by playing through the moves of the greatest players of all time. Perfect for students of the game, this collection allows you to explore the most famous and instructive matches, move by move, and apply those lessons to your own play. While there is no analysis or commentary, the games themselves offer invaluable insights into the strategies and tactics used by the masters.",
    url: "classicgames",
  },
  {
    title: "Miniature Games",
    image: "library/miniaturegames",
    description:
      "Explore, discover, and analyze a curated collection of Miniature Chess Games, where master players demonstrate their skill in rapid and decisive victories achieved in just a few moves. Delve into these quick masterpieces to gain insights into tactical brilliance and strategic finesse. Perfect for players seeking to understand the art of swift domination on the chessboard, these games showcase how a few well-chosen moves can lead to spectacular outcomes.",
    url: "miniaturegames",
  },
  {
    title: "GIF Generator",
    image: "tools/gifgenerator",
    description:
      "Create and share custom chess GIFs with our easy-to-use GIF Generator. Capture brilliant moves, epic blunders, or memorable moments and showcase them in animated form. This tool is perfect for chess enthusiasts looking to express their creativity and share their love for the game on social media, adding a fun and dynamic element to your online presence.",
    url: "gifgenerator",
  },
  {
    title: "Chess Notation Trainer",
    image: "tools/notationtrainer",
    description:
      "Master chess notation with our interactive trainer, featuring classic chess games. Learn to read and write chess moves with ease, a crucial skill for serious players. Perfect for beginners and those looking to refine their notation skills, this tool offers a hands-on approach to understanding the language of chess, improving your ability to record and analyze games effectively.",
    url: "notationtrainer",
  },
  /*
  {
    title: "Opening Explorer",
    image: "tools/openingexplorer",
    description:
      "Explore thousands of chess openings with our comprehensive Opening Explorer. Study the most successful lines, discover novelties, and enhance your opening repertoire. This essential tool is perfect for players preparing for serious competition, offering deep insights into opening theory and helping you to find the best strategies for your style of play.",
    url: "openingexplorer",
  },*/
];
