import React from "react";
import {
  Typography,
  useTheme,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
} from "@mui/material";
import { tokens } from "../styles/theme";

const MyCard = (props) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  return (
    <a href={props.link} style={{ textDecoration: "none" }}>
      <Card
        sx={{
          height: "95%",
          m: 1,
          boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.3)",
        }}
      >
        <CardActionArea
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <CardMedia
            component="img"
            sx={{ maxHeight: "140px", maxWidth: "500px" }}
            image={props.img}
          />
          <CardContent
            sx={{
              flexGrow: 1,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              width: "100%",
            }}
          >
            <Typography
              gutterBottom
              variant="h6"
              component="div"
              sx={{ color: colors.black[900] }}
            >
              {props.title}
            </Typography>
            <Typography variant="body2" color="text.secondary">
              {props.description}
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </a>
  );
};

export default MyCard;
