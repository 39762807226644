import React, { useState, useEffect } from "react";
import { Box, Typography, useTheme, TextField } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { chessquotes } from "../../../data/quotes/chessquotes";
import { Helmet } from "react-helmet";
import { useShare } from "../../../context/ShareContext";

const Quotes = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/quotes",
      title: "Chessboard Magic - Chess Quotes",
      description:
        "Discover Famous Chess Quotes: A Collection of Inspiring Words",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);
  const [searchTerm, setSearchTerm] = useState("");

  const filteredQuotes = chessquotes.filter(
    (quote) =>
      quote.Author.toLowerCase().includes(searchTerm.toLowerCase()) ||
      quote.Quote.toLowerCase().includes(searchTerm.toLowerCase())
  );
  filteredQuotes.sort((a, b) => (a.Author > b.Author ? 1 : -1));

  return (
    <Box>
      <ContentHeader
        title="Chess Quotes"
        subtitle="Discover Famous Chess Quotes: A Collection of Inspiring Words"
        color={colors.black[900]}
        backgroundImage="./img/header-background.png"
        borderColor={colors.material[6]}
      />
      <Helmet>
        <title>Chess Glossary</title>
        <meta
          name="description"
          content="Discover Famous Chess Quotes: A Collection of Inspiring Words."
        />
        <meta property="og:title" content="Chess Quotes" />
        <meta
          property="og:description"
          content="Discover Famous Chess Quotes: A Collection of Inspiring Words."
        />
        <meta
          property="og:image"
          content="https://chessboardmagic.com/img/library/quotes.png"
        />
        <meta property="og:url" content="https://chessboardmagic.com/quotes" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Quotes" />
        <meta
          name="twitter:description"
          content="Discover Famous Chess Quotes: A Collection of Inspiring Words."
        />
        <meta
          name="twitter:image"
          content="https://chessboardmagic.com/img/library/quotes.png"
        />
      </Helmet>
      <Box
        display="flex"
        justifyContent="space-between"
        alignContent="center"
        flexDirection="column"
        gap={2}
      >
        <TextField
          label="Search"
          variant="outlined"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          fullWidth
          autoComplete="off"
          focused={false}
        />
        {filteredQuotes.map((quote, index) => (
          <Box key={index} sx={{ marginBottom: 1 }}>
            <Typography variant="h6">"{quote.Quote}"</Typography>
            <Typography variant="body1" sx={{ color: colors.black[700] }}>
              - {quote.Author}
            </Typography>
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default Quotes;
