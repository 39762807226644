import React from "react";
import { List, ListItem, ListItemText, Typography } from "@mui/material";

const GuessWhoHelp = () => {
  return (
    <>
      <Typography>
        Welcome to Guess Who! In this game, your chess knowledge will be put to
        the test. Can you identify the famous chess player by their image? Look
        closely, think carefully, and make your best guess!
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="How to Play"
            secondary="An image of a famous chess player will be displayed. Below the image, you'll see a list of names. Your task is to select the correct name that matches the player in the image."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Make Your Guess"
            secondary="Click on the name that you think corresponds to the player in the image. If you guess correctly, you'll get a confirmation. If you guess incorrectly, you'll have another chance!"
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Game End"
            secondary="Once you correctly identify the player, the game will end, and their details will be shown. You can then start a new round to guess another famous chess player."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Improve Your Chess Knowledge"
            secondary="This game is a fun way to learn more about famous chess players, both past and present. The more you play, the more familiar you'll become with the legends of the game!"
          />
        </ListItem>
      </List>
    </>
  );
};

export default GuessWhoHelp;
