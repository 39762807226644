import React from "react";
import { Box, Typography, useTheme } from "@mui/material";

const ContentHeader = (props) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        p: "15px 0px 10px 10px",
        m: "10px 0px 20px 0px",
        backgroundImage: `url(${props.backgroundImage})`,
        backgroundSize: "auto 50px",
        backgroundPosition: "left top",
        backgroundRepeat: "no-repeat",
        ...(props.borderColor && {
          borderLeft: `3px solid ${props.borderColor}`,
        }),
      }}
    >
      <Typography sx={{ color: props.color }} variant="h5">
        {props.title}
      </Typography>
      {props.subtitle && (
        <Typography
          sx={{
            color: theme.palette.primary,
          }}
        >
          {props.subtitle}
        </Typography>
      )}
    </Box>
  );
};

export default ContentHeader;
