import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import { useShare } from "../../../context/ShareContext";
import MyCard from "../../../components/MyCard";

function OpeningCoursesHomepage() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const { setShareData } = useShare();
  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/chessopenings",
      title: "Chessboard Magic - Chess Openings",
      description:
        "Explore top chess openings with strategies, key moves, and variations to enhance your game from the first move.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  return (
    <Box>
      <ContentHeader
        title="Chess Openings"
        subtitle="Explore top chess openings with strategies, key moves, and variations to enhance your game from the first move."
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[10]}
      />
      <Helmet>
        <title>Chess Openings</title>
        <meta
          name="description"
          content="Explore top chess openings with strategies, key moves, and variations to enhance your game from the first move."
        />
        <meta property="og:title" content="Chess Openings" />
        <meta
          property="og:description"
          content="Explore top chess openings with strategies, key moves, and variations to enhance your game from the first move."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/chessopenings.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/chessopenings`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Chess Openings" />
        <meta
          name="twitter:description"
          content="Explore top chess openings with strategies, key moves, and variations to enhance your game from the first move."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/chessopenings.png`}
        />
      </Helmet>
      <Box>
        <Box sx={{ width: "300px" }}>
          <MyCard
            title="Introducing the Caro-Kann Defense"
            description="Test your knowledge by correctly playing named chess openings, and deepen your familiarity with a variety of openings"
            img="./img/games/playtheopening.png"
            link="playtheopening"
            sx={{ margin: 0 }}
          />
        </Box>
      </Box>
    </Box>
  );
}

export default OpeningCoursesHomepage;
