import React, { useState, useEffect } from "react";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js";
import { Box, Button, Typography, useTheme } from "@mui/material";
import { themeColors } from "../../../styles/boardtheme";
import { pieceSets } from "../../../styles/pieceset";
import { tokens } from "../../../styles/theme";
import { Helmet } from "react-helmet";
import { useUser } from "../../../context/UserContext";

import ContentHeader from "../../../components/ContentHeader";
import PlayCircleOutlineRoundedIcon from "@mui/icons-material/PlayCircleOutlineRounded";

import { trackEvent } from "../../../config/ga";

import { fischerrandom } from "../../../data/fischerrandom/fischerrandom";
import { useShare } from "../../../context/ShareContext";

function FischerRandomGenerator() {
  const [game, setGame] = useState(new Chess());
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const { userData } = useUser();
  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/fischerrandom",
      title: "Chessboard Magic - Fischer Random Generator",
      description:
        "Generate randomized chess positions and experience the unpredictability and excitement of Fischer Random Chess",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);
  function getRandomNumber(n) {
    return Math.floor(Math.random() * n);
  }

  function handleGenerate() {
    trackEvent("Tools", "Tool-Click", "Fischer Random Generator");

    let randomNumber = getRandomNumber(fischerrandom.length);
    let position = fischerrandom[randomNumber];
    const newGame = new Chess(position);
    setGame(newGame);
  }

  return (
    <Box>
      <ContentHeader
        title="Fischer Random Generator"
        subtitle="Generate randomized chess positions and experience the unpredictability and excitement of Fischer Random Chess"
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[14]}
      />
      <Helmet>
        <title>About Chessboard Magic</title>
        <meta
          name="description"
          content="Generate randomized chess positions and experience the unpredictability and excitement of Fischer Random Chess."
        />
        <meta property="og:title" content="Fischer Random Generator" />
        <meta
          property="og:description"
          content="Generate randomized chess positions and experience the unpredictability and excitement of Fischer Random Chess."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/tools/fischerrandom.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/fischerrandom`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Fischer Random Generator" />
        <meta
          name="twitter:description"
          content="Generate randomized chess positions and experience the unpredictability and excitement of Fischer Random Chess."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/tools/fischerrandom.png`}
        />
      </Helmet>
      <Box>
        <Button
          variant="contained"
          onClick={handleGenerate}
          style={{ marginRight: 10 }}
          startIcon={
            <PlayCircleOutlineRoundedIcon
              style={{ color: colors.black[900] }}
            />
          }
        >
          Generate
        </Button>
        <Box
          sx={{
            p: "20px 0px 0px 0px",
            display: "flex",
            flexDirection: {
              xs: "column", // All screens smaller than 'sm'
              sm: "column", // All screens smaller than 'md'
              md: "row", // Medium screens and larger
            },
            alignItems: "flex-start", // Align items at the start of the flex container
            width: "100%", // Use the full width of the container
          }}
        >
          <div
            id="chessboard"
            style={{
              width: "100%", // Full width in column layout
              maxWidth: "500px", // Maximum width to constrain the chessboard
              padding: "0px 10px 10px 0px", // Uniform padding
              boxSizing: "border-box", // Include padding and border in the element's total width and height
            }}
          >
            <Chessboard
              position={game.fen()}
              customLightSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "Modern Minimal"].lightSquare,
                backgroundImage:
                  themeColors[userData?.theme || "Modern Minimal"]
                    .lightSquarePattern,
              }}
              customDarkSquareStyle={{
                backgroundColor:
                  themeColors[userData?.theme || "Modern Minimal"].darkSquare,
                backgroundImage:
                  themeColors[userData?.theme || "Modern Minimal"]
                    .darkSquarePattern,
              }}
              customPieces={pieceSets[userData?.pieceset || "Maestro"]}
              areArrowsAllowed={false}
            />
          </div>

          <div
            id="description"
            style={{
              flex: 1,
              overflow: "auto",
              maxWidth: "500px",
              padding: "0px 0px 10px 0px", // Uniform padding
            }}
          >
            <Typography>
              Fischer Random Chess, also called Chess960, is a fun twist on
              regular chess created by former World Chess Champion Bobby
              Fischer. Instead of starting with the usual setup, the back-row
              pieces (like the rooks, knights, and bishops) are mixed up
              randomly, creating 960 possible starting positions. The rules are
              the same as normal chess, but because the pieces start in
              different places each game, players can't rely on memorized moves
              and must think creatively from the start. This makes the game more
              exciting and fair, especially for beginners and casual players.
            </Typography>
          </div>
        </Box>
      </Box>
    </Box>
  );
}

export default FischerRandomGenerator;
