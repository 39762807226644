import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from "@mui/material";
import { Chessboard } from "react-chessboard";
import { Chess } from "chess.js"; // For handling game logic
import ContentHeader from "../../../components/ContentHeader";

function HandAndBrain() {
  const [game, setGame] = useState(new Chess());
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [color, setColor] = useState("white");
  const [strength, setStrength] = useState(12); // Depth of Stockfish
  const [movesHistory, setMovesHistory] = useState([]);
  const [recommendedPiece, setRecommendedPiece] = useState(null);
  const stockfishWorker = useRef(null);

  // Load Stockfish as a Web Worker from the public folder
  useEffect(() => {
    stockfishWorker.current = new Worker(
      `${process.env.PUBLIC_URL}/js/stockfish-16.1-single.js`
    );

    return () => {
      if (stockfishWorker.current) {
        stockfishWorker.current.terminate();
      }
    };
  }, []);

  // Function to handle opening settings dialog
  const openDialog = () => setDialogOpen(true);

  // Function to close settings dialog and start the game
  const startGame = () => {
    setDialogOpen(false);
    setGame(new Chess()); // Reset game
    setMovesHistory([]);
    setRecommendedPiece(null);
  };

  // Handle moves and game logic
  const onDrop = (sourceSquare, targetSquare) => {
    const move = game.move({
      from: sourceSquare,
      to: targetSquare,
      promotion: "q", // Always promote to a queen for simplicity
    });

    if (move) {
      setGame({ ...game });
      setMovesHistory([...movesHistory, move]);

      // After player's move, get Stockfish to recommend a move
      getPieceRecommendation();
    }
  };

  // Function to let Stockfish recommend a piece for the player to move
  const getPieceRecommendation = () => {
    if (stockfishWorker.current) {
      // Post the current FEN and ask Stockfish for the best move
      stockfishWorker.current.postMessage(`position fen ${game.FEN}`);
      stockfishWorker.current.postMessage(`go depth ${strength}`);

      stockfishWorker.current.onmessage = (event) => {
        const message = event.data;
        if (message.startsWith("bestmove")) {
          const move = message.split(" ")[1]; // e.g., "e2e4"
          const piece = game.get(move.substring(0, 2)).type; // Get piece type from the best move
          setRecommendedPiece(piece);
        }
      };
    }
  };

  return (
    <Box>
      <ContentHeader
        title="Hand And Brain"
        subtitle="The engine suggests which piece to move, and you decide how and where to move it."
      />

      <Button variant="contained" onClick={openDialog}>
        Start Game
      </Button>

      {/* Settings Dialog */}
      <Dialog open={isDialogOpen} onClose={() => setDialogOpen(false)}>
        <DialogTitle>Game Settings</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Color</InputLabel>
            <Select value={color} onChange={(e) => setColor(e.target.value)}>
              <MenuItem value="white">White</MenuItem>
              <MenuItem value="black">Black</MenuItem>
              <MenuItem value="random">Random</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth>
            <InputLabel>Engine Strength (Depth)</InputLabel>
            <Select
              value={strength}
              onChange={(e) => setStrength(e.target.value)}
            >
              {[...Array(20).keys()].map((depth) => (
                <MenuItem key={depth} value={depth + 1}>
                  {depth + 1}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={startGame}
            variant="contained"
            sx={{
              backgroundColor: "green",
              color: "white",
              "&:hover": {
                backgroundColor: "darkgreen", // optional hover effect
              },
            }}
          >
            Start Game
          </Button>
        </DialogActions>
      </Dialog>

      <Box display="flex" mt={2}>
        {/* Chessboard */}
        <Box flex={1}>
          <Chessboard
            position={game.FEN}
            onPieceDrop={onDrop}
            arePiecesDraggable={true}
          />
        </Box>

        {/* Right Panel: Moves History and Engine Recommendation */}
        <Box flex={1} ml={2}>
          <h3>
            Recommended Piece: {recommendedPiece ? recommendedPiece : "N/A"}
          </h3>
          <h3>Moves History</h3>
          <ul>
            {movesHistory.map((move, index) => (
              <li key={index}>{move.san}</li>
            ))}
          </ul>
        </Box>
      </Box>
    </Box>
  );
}

export default HandAndBrain;
