import React from "react";
import { Box, Typography, List, ListItem, ListItemText } from "@mui/material";

const GuessTheMoveHelp = () => {
  const listItemTextStyle = {
    fontSize: "0.875rem",
    color: "rgba(0, 0, 0, 0.6)",
  };

  return (
    <Box>
      <Typography component="div">
        "Guess the Move" is an exciting chess challenge that tests your ability
        to predict the best moves in various positions. Improve your chess
        intuition by trying to match the engine's next move!
      </Typography>
      <List>
        <ListItem>
          <ListItemText
            primary="Start the Game"
            secondary="Click on Play to be presented with a random chess position. Analyze the position carefully before deciding on your move."
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Make Your Move"
            secondary={
              <>
                <Typography component="div" style={listItemTextStyle}>
                  Based on the position, make your best move to match the
                  engine's recommendation:
                </Typography>
                <Box component="div" sx={{ pl: 2 }}>
                  <List>
                    <ListItem>
                      <ListItemText
                        primary="Correct Move"
                        secondary="If you guessed the correct move, the game ends, and you can start a new challenge by clicking Play again."
                        style={listItemTextStyle}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Incorrect Move"
                        secondary="If your move is incorrect, you'll have two more chances to guess the correct move."
                        style={listItemTextStyle}
                      />
                    </ListItem>
                    <ListItem>
                      <ListItemText
                        primary="Three Chances"
                        secondary="You have a total of three chances to find the correct move. If all attempts are wrong, the game will reset to the original position."
                        style={listItemTextStyle}
                      />
                    </ListItem>
                  </List>
                </Box>
              </>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Check Your Progress"
            secondary={
              <Typography component="div" style={listItemTextStyle}>
                After each attempt, you'll receive feedback on whether your move
                was correct. Use this feedback to improve your understanding of
                the position.
              </Typography>
            }
          />
        </ListItem>
        <ListItem>
          <ListItemText
            primary="Keep Practicing"
            secondary="Don't worry if you don't guess the correct move right away! Each challenge helps you improve your ability to calculate and evaluate positions. Keep practicing and enhance your chess skills."
          />
        </ListItem>
      </List>
    </Box>
  );
};

export default GuessTheMoveHelp;
