import React, { useEffect } from "react";
import { Box, Typography, useTheme } from "@mui/material";
import ContentHeader from "../../../components/ContentHeader";
import { tokens } from "../../../styles/theme";
import PositionViewer from "../../../components/PositionViewer";
import { rulesofchess } from "../../../data/learn/rulesofchess";
import { Helmet } from "react-helmet";
import { useShare } from "../../../context/ShareContext";

function BasicRulesOfPlay() {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const rule_2_3 = rulesofchess.find((rule) => rule.ID === "2.3");
  const rule_3_2 = rulesofchess.find((rule) => rule.ID === "3.2");
  const rule_3_3 = rulesofchess.find((rule) => rule.ID === "3.3");
  const rule_3_4 = rulesofchess.find((rule) => rule.ID === "3.4");
  const rule_3_5 = rulesofchess.find((rule) => rule.ID === "3.6");
  const rule_3_7_3 = rulesofchess.find((rule) => rule.ID === "3.7.3");
  const rule_3_7_3_2 = rulesofchess.find((rule) => rule.ID === "3.7.3.2");
  const rule_3_8_1 = rulesofchess.find((rule) => rule.ID === "3.8.1");
  const rule_3_8_2a = rulesofchess.find((rule) => rule.ID === "3.8.2a");
  const rule_3_8_2b = rulesofchess.find((rule) => rule.ID === "3.8.2b");
  const rule_3_8_2c = rulesofchess.find((rule) => rule.ID === "3.8.2c");
  const rule_3_8_2d = rulesofchess.find((rule) => rule.ID === "3.8.2d");

  const { setShareData } = useShare();

  useEffect(() => {
    const newShareData = {
      url: "https://chessboardmagic.com/basicrulesofplay",
      title: "Chessboard Magic - Basic Rules of Play",
      description:
        "Basic chess rules cover piece movement, check and checkmate, castling, pawn promotion, and the initial setup of the board.",
    };

    // Update the ShareContext
    setShareData(newShareData);
  }, [setShareData]);

  return (
    <Box>
      <ContentHeader
        title="FIDE Basic Rules Of Play"
        subtitle="(January 2023) Basic chess rules cover piece movement, check and checkmate, castling, pawn promotion, and the initial setup of the board."
        color={colors.black[900]}
        backgroundImage={`${process.env.PUBLIC_URL}/img/header-background.png`}
        borderColor={colors.material[6]}
      />
      <Helmet>
        <title>Basic Rules Of Play</title>
        <meta
          name="description"
          content="(January 2023) Basic chess rules cover piece movement, check and checkmate, castling, pawn promotion, and the initial setup of the board."
        />
        <meta property="og:title" content="Basic Rules of Play" />
        <meta
          property="og:description"
          content="(January 2023) Basic chess rules cover piece movement, check and checkmate, castling, pawn promotion, and the initial setup of the board."
        />
        <meta
          property="og:image"
          content={`${process.env.PUBLIC_URL}/img/learn/basicrulesofplay.png`}
        />
        <meta
          property="og:url"
          content={`${process.env.PUBLIC_URL}/basicrulesofplay`}
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Basic Rules of Play" />
        <meta
          name="twitter:description"
          content="(January 2023) Basic chess rules cover piece movement, check and checkmate, castling, pawn promotion, and the initial setup of the board."
        />
        <meta
          name="twitter:image"
          content={`${process.env.PUBLIC_URL}/img/learn/basicrulesofplay.png`}
        />
      </Helmet>
      <Box sx={{ maxWidth: "600px" }}>
        <Typography variant="h6" gutterBottom>
          Article 1: The Nature and Objectives of the Game of Chess
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>1.1</strong> The game of chess is played between two opponents
          who move their pieces on a square board called a ‘chessboard’.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>1.2</strong> The player with the light-coloured pieces (White)
          makes the first move, then the players move alternately, with the
          player with the dark-coloured pieces (Black) making the next move.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>1.3</strong> A player is said to ‘have the move’ when his/her
          opponent’s move has been ‘made’.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>1.4</strong> The objective of each player is to place the
          opponent’s king ‘under attack’ in such a way that the opponent has no
          legal move.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>1.4.1</strong> The player who achieves this goal is said to
          have ‘checkmated’ the opponent’s king and to have won the game.
          Leaving one’s own king under attack, exposing one’s own king to attack
          and also ’capturing’ the opponent’s king is not allowed.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>1.4.2</strong> The opponent whose king has been checkmated has
          lost the game.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>1.5</strong> If the position is such that neither player can
          possibly checkmate the opponent’s king, the game is drawn (see Article
          5.2.2).
        </Typography>

        <Typography variant="h6" gutterBottom>
          Article 2: The Initial Position of the Pieces on the Chessboard
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2.1</strong> The chessboard is composed of an 8 x 8 grid of 64
          equal squares alternately light (the ‘white’ squares) and dark (the
          ‘black’ squares). The chessboard is placed between the players in such
          a way that the near corner square to the right of the player is white.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2.2</strong> At the beginning of the game White has 16
          light-coloured pieces (the ‘white’ pieces); Black has 16 dark-coloured
          pieces (the ‘black’ pieces).
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>2.3</strong> The initial position of the pieces on the
          chessboard is as follows:
        </Typography>
        <PositionViewer FEN={rule_2_3.FEN} />
        <Typography variant="body1" paragraph>
          <strong>2.4</strong> The eight vertical columns of squares are called
          ‘files’. The eight horizontal rows of squares are called ‘ranks’. A
          straight line of squares of the same colour, running from one edge of
          the board to an adjacent edge, is called a ‘diagonal’.
        </Typography>
        <Typography variant="h6" gutterBottom>
          Article 3: The Moves of the Pieces
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3.1</strong> It is not permitted to move a piece to a square
          occupied by a piece of the same colour.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.1.1</strong> If a piece moves to a square occupied by an
          opponent’s piece the latter is captured and removed from the
          chessboard as part of the same move.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.1.2</strong> A piece is said to attack an opponent’s piece
          if the piece could make a capture on that square according to Articles
          3.2 to 3.8.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.1.3</strong> A piece is considered to attack a square even
          if this piece is constrained from moving to that square because it
          would then leave or place the king of its own colour under attack.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3.2</strong> The bishop may move to any square along a
          diagonal on which it stands.
        </Typography>

        <PositionViewer
          FEN={rule_3_2.FEN}
          SquareHighlights={rule_3_2.SquareHighlights}
        />
        <Typography variant="body1" paragraph>
          <strong>3.3</strong> The rook may move to any square along the file or
          the rank on which it stands.
        </Typography>
        <PositionViewer
          FEN={rule_3_3.FEN}
          SquareHighlights={rule_3_3.SquareHighlights}
        />
        <Typography variant="body1" paragraph>
          <strong>3.4</strong> The queen may move to any square along the file,
          the rank or a diagonal on which it stands.
        </Typography>
        <PositionViewer
          FEN={rule_3_4.FEN}
          SquareHighlights={rule_3_4.SquareHighlights}
        />
        <Typography variant="body1" paragraph>
          <strong>3.5</strong> When making these moves, the bishop, rook or
          queen may not move over any intervening pieces.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3.6</strong> The knight may move to one of the squares nearest
          to that on which it stands but not on the same rank, file or diagonal.
        </Typography>

        <PositionViewer
          FEN={rule_3_5.FEN}
          SquareHighlights={rule_3_5.SquareHighlights}
        />

        <Typography variant="body1" paragraph>
          <strong>3.7</strong> The pawn:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.7.1</strong> The pawn may move forward to the square
          immediately in front of it on the same file, provided that this square
          is unoccupied, or
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.7.2</strong> On its first move the pawn may move as in 3.7.1
          or alternatively it may advance two squares along the same file,
          provided that both squares are unoccupied, or
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.7.3</strong> The pawn may move to a square occupied by an
          opponent’s piece diagonally in front of it on an adjacent file,
          capturing that piece.
        </Typography>
        <PositionViewer
          FEN={rule_3_7_3.FEN}
          SquareHighlights={rule_3_7_3.SquareHighlights}
          ArrowsHighlights={rule_3_7_3.ArrowsHighlights}
          ComponentPadding="0px 0px 0px 20px"
        />
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>3.7.3.1</strong> A pawn occupying a square on the same rank as
          and on an adjacent file to an opponent’s pawn which has just advanced
          two squares in one move from its original square may capture this
          opponent’s pawn as though the latter had been moved only one square.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>3.7.3.2</strong> This capture is only legal on the move
          following this advance and is called an ‘en passant’ capture.
        </Typography>
        <PositionViewer
          FEN={rule_3_7_3_2.FEN}
          SquareHighlights={rule_3_7_3_2.SquareHighlights}
          ArrowsHighlights={rule_3_7_3_2.ArrowsHighlights}
          ComponentPadding="0px 0px 0px 40px"
        />
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>3.7.3.3</strong> When a player, having the move, plays a pawn
          to the rank furthest from its starting position, he/she must exchange
          that pawn as part of the same move for a new queen, rook, bishop or
          knight of the same colour on the intended square of arrival. This is
          called the square of ‘promotion’.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>3.7.3.4</strong> The player's choice is not restricted to
          pieces that have been captured previously.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "40px" }}>
          <strong>3.7.3.5</strong> This exchange of a pawn for another piece is
          called promotion, and the effect of the new piece is immediate.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3.8</strong> There are two different ways of moving the king:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.8.1</strong> By moving to an adjoining square
        </Typography>
        <PositionViewer
          FEN={rule_3_8_1.FEN}
          SquareHighlights={rule_3_8_1.SquareHighlights}
          ComponentPadding="0px 0px 0px 20px"
        />
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.8.2</strong> By ‘castling’. This is a move of the king and
          either rook of the same colour along the player’s first rank, counting
          as a single move of the king and executed as follows: the king is
          transferred from its original square two squares towards the rook on
          its original square, then that rook is transferred to the square the
          king has just crossed.
        </Typography>

        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: "0px 0px 0px 20px",
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              maxWidth: "250px",
              boxSizing: "border-box",
              overflow: "auto",
            }}
          >
            <PositionViewer FEN={rule_3_8_2a.FEN} />
            <Typography>
              Before white kingside castling, Before black queenside castling
            </Typography>
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              maxWidth: "250px",
              boxSizing: "border-box",
              overflow: "auto",
            }}
          >
            <PositionViewer FEN={rule_3_8_2b.FEN} />
            <Typography>
              After white kingside castling, After black queenside castling
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            padding: "0px 0px 0px 20px",
          }}
        >
          <Box
            sx={{
              flexBasis: "50%",
              maxWidth: "250px",
              boxSizing: "border-box",
              overflow: "auto",
            }}
          >
            <PositionViewer FEN={rule_3_8_2c.FEN} />
            <Typography>
              Before white queenside castling, Before black kingside castling
            </Typography>
          </Box>
          <Box
            sx={{
              flexBasis: "50%",
              maxWidth: "250px",
              boxSizing: "border-box",
              overflow: "auto",
            }}
          >
            <PositionViewer FEN={rule_3_8_2d.FEN} />
            <Typography>
              After white queenside castling, After black kingside castling
            </Typography>
          </Box>
        </Box>
        <Typography variant="h6" sx={{ pt: 1 }} gutterBottom>
          Article 3: The Moves of the Pieces
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3.8.2.1</strong> The right to castle has been lost:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          1) If the king has already moved, or
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          2) With a rook that has already moved.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3.8.2.2</strong> Castling is prevented temporarily:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          3) If the square on which the king stands, or the square which it must
          cross, or the square which it is to occupy, is attacked by one or more
          of the opponent's pieces, or
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          4) If there is any piece between the king and the rook with which
          castling is to be effected.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3.9</strong> The king in check:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.9.1</strong> The king is said to be 'in check' if it is
          attacked by one or more of the opponent's pieces, even if such pieces
          are constrained from moving to the square occupied by the king because
          they would then leave or place their own king in check.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.9.2</strong> No piece can be moved that will either expose
          the king of the same colour to check or leave that king in check.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>3.10</strong> Legal and illegal moves; illegal positions:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.10.1</strong> A move is legal when all the relevant
          requirements of Articles 3.1 – 3.9 have been fulfilled.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.10.2</strong> A move is illegal when it fails to meet the
          relevant requirements of Articles 3.1 – 3.9.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>3.10.3</strong> A position is illegal when it cannot have been
          reached by any series of legal moves.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Article 4: The Act of Moving the Pieces
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.1</strong> Each move must be played with one hand only.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.2</strong> Adjusting the pieces or other physical contact
          with a piece:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.2.1</strong> Only the player having the move may adjust one
          or more pieces on their squares, provided that he/she first expresses
          his/her intention (for example by saying “j’adoube” or “I adjust”).
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.2.2</strong> Any other physical contact with a piece, except
          for clearly accidental contact, shall be considered to be intent.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.3</strong> Except as provided in Article 4.2.1, if the
          player having the move touches on the chessboard, with the intention
          of moving or capturing:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.3.1</strong> one or more of his/her own pieces, he/she must
          move the first piece touched that can be moved.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.3.2</strong> one or more of his/her opponent’s pieces,
          he/she must capture the first piece touched that can be captured.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.3.3</strong> one or more pieces of each colour, he/she must
          capture the first touched opponent’s piece with his/her first touched
          piece or, if this is illegal, move or capture the first piece touched
          that can be moved or captured. If it is unclear whether the player’s
          own piece or his/her opponent’s piece was touched first, the player’s
          own piece shall be considered to have been touched before his/her
          opponent’s.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.4</strong> If a player having the move:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.4.1</strong> touches his/her king and a rook he/she must
          castle on that side if it is legal to do so
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.4.2</strong> deliberately touches a rook and then his/her
          king he/she is not allowed to castle on that side on that move and the
          situation shall be governed by Article 4.3.1.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.4.3</strong> intending to castle, touches the king and then
          a rook, but castling with this rook is illegal, the player must make
          another legal move with his/her king (which may include castling with
          the other rook). If the king has no legal move, the player is free to
          make any legal move.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.4.4</strong> promotes a pawn, the choice of the piece is
          finalised when the piece has touched the square of promotion.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.5</strong> If none of the pieces touched in accordance with
          Article 4.3 or Article 4.4 can be moved or captured, the player may
          make any legal move.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.6</strong> The act of promotion may be performed in various
          ways:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.6.1</strong> the pawn does not have to be placed on the
          square of arrival.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.6.2</strong> removing the pawn and putting the new piece on
          the square of promotion may occur in any order.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.6.3</strong> If an opponent’s piece stands on the square of
          promotion, it must be captured.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.7</strong> When, as a legal move or part of a legal move, a
          piece has been released on a square, it cannot be moved to another
          square on this move. The move is considered to have been made in the
          case of:
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.7.1</strong> A capture, when the captured piece has been
          removed from the chessboard and the player, having placed his/her own
          piece on its new square, has released this capturing piece from
          his/her hand.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.7.2</strong> Castling, when the player's hand has released
          the rook on the square previously crossed by the king. When the player
          has released the king from his/her hand, the move is not yet made, but
          the player no longer has the right to make any move other than
          castling on that side, if this is legal. If castling on this side is
          illegal, the player must make another legal move with his/her king
          (which may include castling with the other rook). If the king has no
          legal move, the player is free to make any legal move.
        </Typography>
        <Typography variant="body1" paragraph style={{ marginLeft: "20px" }}>
          <strong>4.7.3</strong> Promotion, when the player's hand has released
          the new piece on the square of promotion and the pawn has been removed
          from the board.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.8</strong> A player forfeits his/her right to claim against
          his/her opponent’s violation of Articles 4.1 – 4.7 once the player
          touches a piece with the intention of moving or capturing it.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>4.9</strong> If a player is unable to move the pieces, an
          assistant, who shall be acceptable to the arbiter, may be provided by
          the player to perform this operation.
        </Typography>

        <Typography variant="h5" gutterBottom>
          Article 5: The Completion of the Game
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5.1.1</strong> The game is won by the player who has
          checkmated his/her opponent’s king. This immediately ends the game,
          provided that the move producing the checkmate position was in
          accordance with Article 3 and Articles 4.2 – 4.7.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5.1.2</strong> The game is lost by the player who declares
          he/she resigns (this immediately ends the game), unless the position
          is such that the opponent cannot checkmate the player’s king by any
          possible series of legal moves. In this case the result of the game is
          a draw.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5.2.1</strong> The game is drawn when the player to move has
          no legal move and his/her king is not in check. The game is said to
          end in ‘stalemate’. This immediately ends the game, provided that the
          move producing the stalemate position was in accordance with Article 3
          and Articles 4.2 – 4.7.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5.2.2</strong> The game is drawn when a position has arisen in
          which neither player can checkmate the opponent’s king with any series
          of legal moves. The game is said to end in a ‘dead position’. This
          immediately ends the game, provided that the move producing the
          position was in accordance with Article 3 and Articles 4.2 – 4.7.
        </Typography>
        <Typography variant="body1" paragraph>
          <strong>5.2.3</strong> The game is drawn upon agreement between the
          two players during the game, provided both players have made at least
          one move. This immediately ends the game.
        </Typography>
      </Box>
    </Box>
  );
}

export default BasicRulesOfPlay;
